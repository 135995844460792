.form {
  @include round-corners(0 0 $corner-radius*2 $corner-radius*2);
  background: white;
  padding: $space/2;
  overflow-y: auto;
  .tab { @include column(1/2, $gutter:5, $cycle:2); }

  @include ipad-p {
    .tab { @include stack(); }
    .cols--2 {
      @include stack();
      padding-top: $space-tablet*(0.8);
      &:first-of-type {
        padding-top: 0;
      }
    }
  }
  @include mobile {
    padding: 1.5rem;
    margin: 0 -1rem;
    .tab { @include stack(); }
    .cols--2 {
      @include stack();
      padding-top: $space-tablet*(0.8);
      &:first-of-type {
        padding-top: 0;
      }
    }
  }
}



.form--enrol, .form--book-event {
  .tab { @include stack(); }
}

div.fields {
  overflow-y: auto;
}
.required label::after {
  content: "*";
  color: red;
}
div.submit {
  text-align: right;
  clear: both;
  @include mobile { text-align: left; }
}


fieldset {
  border: none;
  background: transparent;
  margin-top: $space/2;
  &:last-of-type { margin-bottom: $space; }
}
legend {
  font-weight: 500;
  color: $brand-01;
}
label {
  font-weight: 300;
}




.form {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 400;
    font-size: $base-font-size;
    padding: $space*(0.2) $space*(0.25);
    border-radius: $form-radius;
    border: none;
    background-color: $ui-wash;
    width: 100%;
  
    margin: .5rem 0 1.3rem;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    @include ipad-l { font-size: $base-font-size - 0.2rem; }
    @include ipad-p { font-size: $base-font-size * 0.8; }
    @include mobile { font-size: $base-font-size * 0.9; }
  }
  
  input[type='number'] {
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 400;
    font-size: $base-font-size;
    padding: $space*(0.2) $space*(0.15) $space*(0.2) $space*(0.25);
    border-radius: $form-radius;
    border: none;
    background-color: $ui-wash;
  
    margin: .5rem 0 1.3rem;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    @include ipad-l { font-size: $base-font-size - 0.2rem; }
    @include ipad-p { font-size: $base-font-size * 0.8; }
    @include mobile { font-size: $base-font-size * 0.9; }
  }
  
  input[type='submit'],
  button[type='submit'] {
    border: none;
    background-color: $brand-01;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    padding: $space/3 $space/1.5;
    border-radius: $button-radius;
    @include fade(.4s);
  
    &:hover {
      background-color: $ui-dark-grey;;
      @include fade(.4s);
    }
  
    @include ipad-l {
      font-size: $base-font-size - 0.2rem;
      padding: $space-tablet/3 $space-tablet/1.5;
    }
    @include ipad-p {
      font-size: $base-font-size * 0.8;
      padding: $space-tablet/3 $space-tablet/1.5;
    }
    @include mobile {
      font-size: $base-font-size;
      padding: $space-mobile/3 $space-mobile/1.5;
    }
  }
  
  input[type="file"],
  ::-webkit-file-upload-button {
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
    margin: .5rem 0;
    border-radius: $form-radius;
    @include fade(.4s);
  
    @include ipad-l { font-size: $base-font-size - 0.2rem; }
    @include ipad-p { font-size: $base-font-size * 0.8; }
  }
  
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance:none;
    background: transparent;
    border: none;
    max-width: 100%;
    font-size: $base-font-size;
    height: 3.4rem;
    padding: .5rem 5rem .5rem 1rem;
    border: none;
    background-color: $ui-wash;
    margin: .5rem 0 1.3rem;
    background-image: url('../assets/ui/ui-dropdown-arrow.png');
    background-repeat: no-repeat;
    background-position: right;
  
     @include ipad-l { font-size: $base-font-size - 0.2rem; }
     @include ipad-p { font-size: $base-font-size * 0.8; }
    @include mobile { font-size: $base-font-size * 0.9; max-width: 100%; }
  }
  
  
  input[type=radio], input[type=checkbox] {
      display:none;
  }
  
  input[type=radio] + label:before {
      content: "";
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      vertical-align:middle;
      margin: 0 8px 2px 0;
      background-color: white;
      border: 4px solid lighten($ui-light-grey, 20%);
      border-radius: 50%;
      cursor: pointer;
  }
  input[type=radio]:checked + label:before {
      content: "";
      color:white;
      background-color: $brand-01;
      border: 4px solid lighten($ui-light-grey, 20%);
      font-size:1.35em;
      text-align:center;
      line-height:18px;
  
      @include ipad-l { font-size: $base-font-size - 0.2rem; }
      @include ipad-p { font-size: $base-font-size * 0.8; }
      @include mobile { font-size: $base-font-size * 0.9; }
  }
  
  input[type=checkbox] + label:before {
      content: "";
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      vertical-align:middle;
      margin: 0 8px 2px 0;
      background-color: lighten($ui-light-grey, 20%);
      border-radius: 3px;
      padding: 2px;
      cursor: pointer;
  }
  input[type=checkbox]:checked + label:before {
      content:"\2714"; /* Tick */
      color:white;
      background-color: $brand-01;
      text-align:center;
      line-height:16px;
  }
}


