// layout
$site-max-width: 144rem;
$site-bk-color: white;

$space: 5rem;

$space-lrg: 5rem;
$space-med: 3.5rem;
$space-sml: 2rem;

$side-icon-margin: 9rem;
$sidebar-width: 24%;
$sidebar-width-tablet: 30%;

$space-tablet: 3.75rem;
$space-mobile: 2.5rem;

// colours
$brand-01: #00B6BA; //aqua
$brand-02: #DB4034; //red
$brand-03: #FE691D; //orange
$brand-04: #FDDC00; //yellow
$brand-05: #AAAAAA; //grey
$brand-06: #DADADA; //light-grey

// legacy variables
$ui-primary: $brand-03;
$ui-accent: $brand-02;
$ui-dark: darken($brand-01, 8%);
$ui-cool:$brand-01;
$ui-warm: $brand-05;
$ui-bright: $brand-04;

$ui-dark-grey: #292929;
$ui-mid-grey: #AAAAAA;
$ui-light-grey: #DADADA;
$ui-wash: #f4f4f4;

$ui-text: #5A5A5A;
$ui-text-alert: $ui-warm;

// fonts
$base-font-size: 1.6rem;
$base-font-weight: 300;
$base-line-height: 1.5;

$base-line-height-tablet: 1.4;

// UI detailing
$corner-radius: 0;
$button-radius: 8rem;
$form-radius: 0;

// padding
$header-padding: 2rem;
$page-padding: 6rem;

// header background
$header-bkgd-col: $ui-primary;

// desktop menu
$dtop-menu-text: $ui-text;
$dtop-menu-padding: 1.2rem;
$dtop-menu-bar-col: white;
$dtop-menu-button-col: #ddd;

// device menu
$mob-menu-button-height: 4rem;
$mob-menu-button-width: 4rem;
$mob-menu-button-bkgd: $ui-accent;
$mob-menu-button-bars: white;
$mob-menu-overlay-bkgd: rgba(255,255,255,1);
$mob-menu-link-col: $ui-primary;
