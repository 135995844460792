.event-overview {
  @include round-corners($corner-radius*(2));
  overflow: hidden;
  background-color: $ui-dark;
}

.event-overview__date {
  padding: $space*(0.5);
  background-color: $brand-02;
  color: white;
  text-align: center;
  h1, h2, h3, h4, h5, h6 {color: white; margin: 0; line-height: 1}
}
.event-overview__date__category {
  @include round-corners($corner-radius);
  display: inline-block;
  border: 2px solid white;
  padding: .4rem 1.5rem;
  margin-bottom: $space*(0.35);
}

.event-overview__details {
  padding: $space*(0.5);
  color: white;
  text-align: center;
}
.event-overview__details__label {
  text-transform: uppercase;
  font-weight: 400;
  color: $ui-primary;
  font-size: $base-font-size*(0.8)
}
.event-overview__details__cost {
  margin-bottom: $space*(0.5);
  color: white;
}
