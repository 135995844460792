.swlife-grid {

    @include tablet-portrait-up {
        display: flex;
        justify-content: center;
    }

    &__list, .card {
        @include mobile {
            &:not(:last-child){
                margin-bottom: $space-sml;
            }
        }
        
        @include tablet-portrait-up {
            flex: 1;
            max-width: 40%;
            &:not(:last-child) { margin-right: $space-med; }
        }
    }

    &__list-title {
        padding: .75rem 1.2rem;
        color: white;
        margin-bottom: .3rem;
    }

    &__list-footer {
        padding: 2rem 1rem;
        text-align: center;
    }

}