.quote-slider {
  padding: 0 $space;
  position: relative;
  margin-bottom: $space/2;
  @include ipad-l { margin-bottom: $space-tablet/3; }
  @include ipad-p { margin-bottom: $space-tablet/3; }
  @include mobile {
    padding: 1rem $space-mobile/2;
    margin: 0 0 0 0;
  }


  &__card {
    text-align: center;
    padding: 0 2rem;

    &-image {
      margin: 0 auto 2rem;
    }
  }

  &__category {
    font-weight: 500;
    margin-bottom: 2rem;
  }

}
