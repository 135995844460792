.photos {

}

.photos__list-item {
  @include column(1/4, $gutter:4, $cycle:4);
  margin-top: $space;
  &:nth-child(-n+3) {margin-top: 0;}
  @include ipad-p { @include column(1/3, $gutter:4, $cycle:3); }
  @include mobile { @include stack(); margin-bottom: $space-mobile; }
}
