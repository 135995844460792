.u-margin-top {
    margin-top: $space-sml;
    @include tablet-portrait-up {
        margin-top: $space-med;
    }
}

.u-rule-top {
    margin-top: $space-sml;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: $space-sml;
    @include tablet-portrait-up {
        margin-top: $space-med;
        padding-top: $space-med;
    }
}

.u-margin-b {
    margin-bottom: $space-med;
}
.u-margin-b-1 {
    @include mobile {
        margin-bottom: 1rem;
    }
}

.u-narrow {
    @include tablet-portrait-up {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.u-centred {
    text-align: center;
}

.u-hide-1 {
    @include mobile {
        display: none;
    }
}