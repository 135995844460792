.private-lessons {
  @include round-corners($corner-radius*2);
  width: 100%;
  display: table;
  background-color: $ui-wash;
  margin-bottom: $space*(0.5);
}

.private-lessons__container {
  display: table-row;
}

.private-lessons__container-l {
  display: table-cell;
  vertical-align: middle;
  padding: $space*(0.25);
  width: 13rem;
  text-align: center;
}

.private-lessons__container-r {
  display: table-cell;
  vertical-align: middle;
  padding: $space*(0.25) $space*(0.25) $space*(0.25) 0;
}

.private-lessons__icon {
  width: 7rem;
}

.private-lessons__tooltip {
  @include round-corners($corner-radius*2);
  padding: $space*(0.25) $space*(0.35);
  background-color: $ui-dark;
  color: white;
  text-align: left;

}
