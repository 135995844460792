.banner-image {
  position: relative;
  background-color: rgb(55, 60, 66);
  background-size: cover;
  background-position: center;
  overflow: hidden;


  &__image {
    display: block;
    width: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center;
    color: white;
  
    h1, h2, h3, h4, h5, h6 {color: white}
  
    &--letterbox {
      background: -moz-linear-gradient(-45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left:0;
    right:0;
    transform: translateY(-50%);
    padding: $space $space*5;
    text-align: center;
    color: white;
    h1, h2, h3, h4, h5, h6 {color: white}
    @include ipad-l { padding: $space $space*2; }
    @include ipad-p { padding: $space $space*1.5; }
    @include mobile { padding: $space-mobile; }

    &--shallow {
      top:0;
      transform: none;
      text-align: left;
      padding: $space/2;
    }

  }



}


.banner-image--hide-mob-overlay .banner-image__overlay,
.banner-image--hide-mob-overlay .banner-image__content {
  @include mobile {
    * {display:none;}
  }
}
.banner-image--hide-mob-overlay .banner-image__overlay.banner-image__overlay--letterbox {
  display: none;
}
