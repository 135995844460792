.sidebar-module > * {
  margin: 0;
}

.sidebar-module {
  @include round-corners($corner-radius*2);
  padding: $space/3;
  margin-bottom: $space/2;
  overflow: hidden;
  h4 {
    margin-bottom: .6rem;
  }
  a.button {
    margin-top: 1rem;
  }

  @include ipad-l { padding: $space-tablet/3; }
  @include ipad-p { padding: $space-tablet/3; }
  @include mobile {
    &:last-of-type { margin-bottom: 0; }
  }
}
