$staff-profiles-img-size: 20rem;
$staff-profiles-space: 2rem;

.staff-profiles {

    padding: $space/2;
    
    @include mobile {
        text-align: center;
    }
    @include tablet-portrait-up {
        padding: $space;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    &__item {
        width: $staff-profiles-img-size;
        display: inline-block;
        margin: $staff-profiles-space/2;
        text-align: center; 

        @include desktop-up {
            margin: $staff-profiles-space;
        }
    }

    &__photo {
        width: $staff-profiles-img-size*(0.75);
        height: $staff-profiles-img-size*(0.75);
        border-radius: 50%;
        transition: all 250ms ease;

        &:hover {
            transform: scale(1.05);
        }

        @include desktop-up {
            width: $staff-profiles-img-size;
            height: $staff-profiles-img-size;
        }

        &--inset {
            float: right;
            width: $staff-profiles-img-size/2;
            height: $staff-profiles-img-size/2;
            margin: 0 0 1rem 1rem;
        };
    }

    &__title {
        margin-top: 1rem;
    }

    &__overlay {
        position: relative;
        padding: $staff-profiles-space*2;
        width: auto;
        max-width: 60rem;
        margin: $staff-profiles-space auto;
        background: #FFF;
        overflow-y: auto;
    }

}
  
// fade (referenced in js init)
.mfp-staff-profiles {
    // before-open state
    .mfp-content,
    .mfp-arrow,
    &.mfp-bg {
        opacity: 0;
        -webkit-backface-visibility: hidden;
        transition: opacity 0.3s ease-out;
    }
    // open state
    &.mfp-ready {
        .mfp-content,
        .mfp-arrow {
            opacity: 1;
        }
        &.mfp-bg {
            opacity: 0.8; // background opacity
        }
    }
    // closed state
    &.mfp-removing {
        .mfp-content,
        .mfp-arrow,
        &.mfp-bg {
            opacity: 0;
        }
    }
}
  