

// previous + next controls
.slider__button {
  @include fade(.4s);
  @include round-corners(50%);
  background-color: rgba(0, 0, 0, 0);
  width: 5rem;
  height: 5rem;
  border: 2px solid white;
  padding: 1rem;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: 1.5rem;
  line-height: 1;
  &:hover {
    background-color: rgba(0, 0, 0, 0.10);
    @include fade(.4s);
  }
  @include ipad-p {
    width: 4rem;
    height: 4rem;
  }
  @include mobile {
    width: 4rem;
    height: 4rem;
  }
}
.slider__button--prev {
 position: absolute;
 top:35%;
 left: 0;
 @include mobile { left: -1.75rem; }
}
.slider__button--next {
  position: absolute;
  top:35%;
  right: 0;
  @include mobile { right: -1.75rem; }
}
.slider__button--primary {
  color: $brand-01;
  border: 2px solid $brand-01;
}
