.category-card {
  @include column(1/4, $cycle:4, $gutter:2);
  margin-top: $space*(0.5);
  text-decoration: none;
  outline: none;
  &:nth-child(-n+4) {margin-top: 0;}

  @include mobile {
    @include column(1/2, $cycle:2, $gutter:2);
  }
}
.category-card:hover .category-card__title-bar {
  @include fade(.4s);
  background-color: darken($ui-primary, 10%);
}


.category-card__image {
  display: block;
  outline: none;
  @include round-corners( $corner-radius*2 $corner-radius*2 0 0 );
}
.category-card__title-bar {
  @include fade(.8s);
  @include round-corners( 0 0 $corner-radius*2 $corner-radius*2 );
  background-color: $ui-primary;
  padding: $space*(0.3);
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200;
  @include ipad-l {
    font-size: 100%;
  }
}
