.map-container {
  background: $ui-wash;
  position: relative;
  overflow-y: auto;
  padding-bottom: $space;
  @include mobile { padding-bottom: 0; }
}

.map-container__sidebar-l {
  float: left;
	width: $sidebar-width;
  padding: 0 0 0 $space;
  @include mobile { float: none; width: 100%; padding: 0 $space-mobile; }
}

.map-container__map {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: $space;
  bottom: $space;
  width: calc(100% - (#{$sidebar-width} + #{$space} + #{$space}));
  @include round-corners($corner-radius*2);
  background: white;
  @include ipad-l {
    right: $space-tablet;
    bottom: $space-tablet;
    width: calc(100% - (#{$sidebar-width} + #{$space-tablet} + #{$space-tablet}));
  }
  @include mobile { 
    position: relative; 
    width: 100%; 
    height: 60vh; 
    margin: 0; 
    right: 0;
    @include round-corners(0)
  }
}
