.event-calendar {

}

// index page
.event-calendar__sidebar {
  float: left;
	width: $sidebar-width;
  padding: $space 0 $space $space;
  @include ipad-l { padding: $space-tablet 0 $space-tablet $space-tablet; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet 0 $space-tablet $space-tablet;
  }
  @include mobile { float: none; width: 100%; padding: $space-mobile $space-mobile 0; }
}
.event-calendar__main-content {
  float: left;
  width: 100%-($sidebar-width);
  padding: $space;
  @include ipad-l { padding: $space-tablet; }
  @include ipad-p { padding: $space-tablet; width: 100%-($sidebar-width-tablet); }
  @include mobile { float: none; width: 100%; padding: $space-mobile; }
}

// list page items

$list-item-padding: $space*(0.3);
$list-item-space-v: $space*(0.3);

.event-calendar__section-divider {
  @include round-corners($corner-radius);
  padding: $space*(0.15) $space*(0.25);
  background-color: $ui-warm;
  margin-bottom: $list-item-space-v;
  h1, h2, h3, h4, h5, h6 {color: white; margin: 0}
}
.event-calendar__list-item {
  @include round-corners($corner-radius);
  background-color: $ui-dark;
  margin-bottom: $list-item-space-v;
  overflow: hidden;
  color: white;
}
.event-calendar__list-item__date {
  width: 20%;
  background-color: $brand-02;
  padding: $list-item-padding;
  text-align: center;
  h1, h2, h3, h4, h5, h6 {color: white; margin: 0; line-height: 1}
  @include mobile { width: 100%; }
}
.event-calendar__list-item__class {
  width: 70%;
  padding: $list-item-padding;
  @include mobile { width: 100%; text-align: center; }
}

.event-calendar__list-item__details {
  width: 10%;
  padding: $list-item-padding;
  text-align: right;
  @include mobile { width: 100%; padding-top: 0; text-align: center; }
}


.event-calendar__list-item {
  display: table;
  width: 100%;
  @include mobile { display: block; }
}
.event-calendar__list-item__container {
  display: table-row;
  @include mobile { display: block; }
}
.event-calendar__list-item__date,
.event-calendar__list-item__class,
.event-calendar__list-item__seats,
.event-calendar__list-item__details {
  display: table-cell;
  vertical-align: middle;
  @include mobile { display: block; }
}



// entry page
.event-calendar__entry__main-content {
  float: left;
  width: 100%-($sidebar-width);
  padding: $space;
  @include ipad-l { padding: $space-tablet; }
  @include ipad-p { padding: $space-tablet; width: 100%-($sidebar-width-tablet); }
  @include mobile { float: none; width: 100%; padding: $space-mobile; }
}
.event-calendar__entry__sidebar {
  float: right;
	width: $sidebar-width;
  padding: $space $space $space 0;
  @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet $space-tablet $space-tablet 0;
  }
  @include mobile { float: none; width: 100%; padding: $space-mobile; }
}




.event-calendar__event__title {
  margin-bottom: .8rem;
  a {font-weight: 400; color: white}
}
.event-calendar__event__timing {
  color: $ui-warm;
}

.event-calendar__list-item__category {
  @include round-corners($corner-radius);
  border: 2px solid white;
  padding: .2rem;
  @include mobile { margin-bottom: 0; }
}
