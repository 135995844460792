$grid-spacing: .2rem;

$card-padding: 1rem;
$button-radius: 2rem;
$button-color: rgba(255, 255, 255, 0.85);
$button-color-hover: rgb(255, 255, 255);

$cat-1: $brand-01; // adults
$cat-2: $brand-03; // teens
$cat-3: #2583cf; // kids
$cat-4: rgb(116, 33, 163); // playgroup
$cat-5: #9b0a63; // other
$cat-6: #89c912; // travellers


.class-calendar {
    
    &__filter {
        @include tablet-portrait-up {
            margin-bottom: 2rem;
        }
        
        
        &__button {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance:none;
            background: transparent;
            border: none;
            border-radius: 0;

            cursor: pointer;
            display: inline-block;
            height: 3.4rem;
            background-color: $ui-wash;
            padding: .6rem 2rem;
            font-size: $base-font-size;
            text-decoration: none;
            color: black;

            transition: all 350ms ease;

            &:hover {
                background-color: darken($ui-wash, 15%);
            }

            @include mobile {
                width: 100%;
            }
        }
        
        &__options {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance:none;
            background: transparent;
            border: none;
            border-radius: 0;
            max-width: 100%;
            font-size: $base-font-size;
            height: 3.4rem;
            padding: .5rem 5rem .5rem 1rem;
            border: none;
            background-color: $ui-wash;
            background-image: url('/assets/ui/ui-dropdown-arrow.png');
            background-repeat: no-repeat;
            background-position: right;
            
            @include mobile {
                width: 100%;
                margin-bottom: 1rem;
            }            
        }
    }

    &__form {
        margin: 0;
    }
    
    &__grid {

        @include tablet-portrait-up {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: $grid-spacing;

        }


        &__header {
            text-align: center;
            padding: 0 1rem 1rem;
            color: #999;
            font-weight: 300;
            
            @include tablet-portrait-up {
                padding: 1rem;
                border-bottom: 3px solid #ddd;
                margin-bottom: .5rem;
            }

            @include mobile {
                font-size: 1.8rem;
                padding-top: 2rem;
            }
        }
    }


    &__card {

        background-color: #b6b6b6;
        &:not(:last-child) {
            margin-bottom: $grid-spacing;
        }

        &__time {
            background-color: rgb(73, 73, 73);
            margin: 0;
            padding: $card-padding;
            color: white;
            font-size: 1.2rem;
        }

        &__details {
            padding: $card-padding;
        }

        &__title {
            margin: 0;
            font-size: 1.5rem;
            color: white;
            margin-bottom: .4rem;
        }

        &__starts {
            font-size: 1.2rem;
            color: white;
            margin-bottom: .4rem;
        }

        &__description {
            margin-bottom: .4rem;
            color: white;
            font-weight: 200;
            font-size: 1.2rem;
            line-height: 1.1;
        }

        &__price {
            color: white;
            margin-bottom: .5rem;
            line-height: 1.1;
            @include desktop-up {
                margin-bottom: 0 !important;
            }

        }

        &__count {
            margin-bottom: .6rem;
            font-size: 1.4rem;
            line-height: 1.2;
            color: white;
        }

        &__cta {
            @include desktop-up {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            @include mobile {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__button {
            background-color: $button-color;
            padding: .5rem 1.5rem;
            font-size: 1.2rem;
            color: white;
            text-decoration: none;
            border-radius: $button-radius;
            transition: all 350ms ease;
            display: inline-block;
            cursor: pointer;
            border: none;

            &:hover {
                background-color: $button-color-hover;
            }
        }

        // modifiers

        &--full {
            // background-color: purple !important;
        }
        &--full &__time {
            color: #888;
        }
        &--full &__price {
            color: rgba(255, 255, 255, 0.4);
        }
        &--full &__button {
            background-color: rgba(255, 255, 255, 0.4);
            cursor: default;
        }

        &--cat-1 {
            background-color: $cat-1;
        }
        &--cat-1 &__button {
            color: $cat-1;
        }

        &--cat-2 {
            background-color: $cat-2;
        }
        &--cat-2 &__button {
            color: $cat-2;
        }

        &--cat-3 {
            background-color: $cat-3;
        }
        &--cat-3 &__button {
            color: $cat-3;
        }

        &--cat-4 {
            background-color: $cat-4;
        }
        &--cat-4 &__button {
            color: $cat-4;
        }

        &--cat-5 {
            background-color: $cat-5;
        }
        &--cat-5 &__button {
            color: $cat-5;
        }

        &--cat-6 {
            background-color: $cat-6;
        }
        &--cat-6 &__button {
            color: $cat-6;
        }

    }

    &__error {
        padding: 2rem;
        text-align: center;
    }

    &__tilt-message {
        display: none;
        @include mobile-portrait {
            display: inline-block;
            margin: 1rem auto 0;
            padding: .5rem 0 .5rem 3.5rem;
            background-image: url('/assets/ui/icon-rotate-phone.svg');
            background-repeat: no-repeat;
            background-size: 2.4rem;
            background-position: center left;
        }
        @include mobile-landscape {
            display: none;
        }
    }

}