@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i");
.testing {
  margin: .5rem 0;
  background-color: orangered;
  padding: .5rem;
  color: white; }

* {
  margin: 0;
  padding: 0;
  font-weight: 300; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  color: #5A5A5A;
  background-color: white; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    body {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    body {
      font-size: 1.28rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    body {
      font-size: 1.44rem; } }

h1, h2, h3, h4, h5, h6 {
  color: #00B6BA;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 200; }

p, ul, ol {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: 300; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    p, ul, ol {
      line-height: 1.4; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    p, ul, ol {
      line-height: 1.4; } }

p.standfirst {
  font-size: 1.92rem;
  margin-bottom: 3.2rem; }

h1 {
  font-size: 4.8rem;
  margin-bottom: 1.75rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h1 {
      font-size: 4rem;
      margin-bottom: 1.65rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h1 {
      font-size: 3.6rem;
      margin-bottom: 1.65rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h1 {
      font-size: 3.2rem;
      margin-bottom: 1rem; } }

h2 {
  font-size: 3.6rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h2 {
      font-size: 3.2rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h2 {
      font-size: 3.2rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h2 {
      font-size: 2.56rem; } }

h3 {
  font-size: 3.2rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h3 {
      font-size: 2.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h3 {
      font-size: 2.4rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h3 {
      font-size: 2.16rem; } }

h4 {
  font-size: 2.08rem;
  font-weight: 400; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h4 {
      font-size: 1.76rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h4 {
      font-size: 1.76rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h4 {
      font-size: 1.92rem; } }

h5 {
  font-size: 1.92rem;
  font-weight: 400; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h5 {
      font-size: 1.6rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h5 {
      font-size: 1.6rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h5 {
      font-size: 1.76rem; } }

h6 {
  font-size: 1.76rem;
  font-weight: 500; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    h6 {
      font-size: 1.44rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    h6 {
      font-size: 1.44rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    h6 {
      font-size: 1.44rem; } }

a {
  color: #00B6BA; }

ul, ol {
  margin-left: 1.6rem; }

strong {
  font-weight: 500; }

img {
  max-width: 100%; }

.dark-bold {
  font-weight: 500;
  color: #5A5A5A; }

.txt-primary {
  color: #FE691D; }

.wrapper {
  max-width: 144rem;
  margin: 0 auto; }

.title-slug {
  padding: .75rem 1.2rem;
  background-color: #00B6BA;
  color: white; }

p a.button, p a.button-cta {
  margin-top: 2rem; }

.color-brand-01 {
  color: #00B6BA; }

.color-brand-02 {
  color: #DB4034; }

.color-brand-03 {
  color: #FE691D; }

.color-brand-04 {
  color: #FDDC00; }

.bg-brand-01 {
  background-color: #00B6BA;
  color: white; }
  .bg-brand-01 * {
    color: white; }

.bg-brand-02 {
  background-color: #DB4034;
  color: white; }
  .bg-brand-02 * {
    color: white; }

.bg-brand-03 {
  background-color: #FE691D;
  color: white; }
  .bg-brand-03 * {
    color: white; }

.bg-brand-04 {
  background-color: #FDDC00; }

.bg-brand-05 {
  background-color: #AAAAAA; }

.bg-brand-06 {
  background-color: #DADADA; }

.bg-white {
  background-color: white; }

.bg-wash {
  background-color: #f4f4f4; }

.bg-warm {
  background-color: #AAAAAA;
  color: white; }
  .bg-warm h1, .bg-warm h2, .bg-warm h3, .bg-warm h4, .bg-warm h5, .bg-warm h6 {
    color: white; }
  .bg-warm a {
    color: white; }

.bg-bright {
  background-color: #FDDC00; }

.bg-primary {
  background-color: #00B6BA;
  color: white; }
  .bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h5, .bg-primary h6 {
    color: white; }

.bg-dark {
  background-color: #00B6BA;
  color: white; }
  .bg-dark h1, .bg-dark h2, .bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6 {
    color: white; }
  .bg-dark a {
    color: white; }

.bg-black {
  background-color: #292929; }

.circular {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: 1.25rem;
  width: 6rem;
  height: 6rem;
  background-color: #dbdada; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .circular {
      width: 5rem;
      height: 5rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .circular {
      width: 4.5rem;
      height: 7rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .circular {
      width: 4rem;
      height: 4rem; } }

.icon {
  border: 0; }

.icon--med {
  height: 5rem;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .icon--med {
      height: 4.25rem; } }

.icon--top {
  height: 4.5rem;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .icon--top {
      height: 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .icon--top {
      height: 3.5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem; } }

.icon--left {
  height: 5.5rem;
  float: left;
  margin-right: 1rem;
  margin-left: -9rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .icon--left {
      height: 4.5rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .icon--left {
      float: none;
      margin: .5rem 0;
      height: 4rem; } }

.social {
  margin-top: 2rem; }
  .social a {
    margin-right: 0.5rem; }

.social__icon {
  height: 3.2rem; }

.pagination {
  clear: both;
  margin-top: 5rem;
  list-style: none;
  margin-left: 0; }
  .pagination li.prev {
    float: left; }
  .pagination li.next {
    float: right; }

.pagination-bar {
  clear: both;
  padding-top: 5rem; }
  .pagination-bar .prev {
    float: left; }
  .pagination-bar .next {
    float: right; }

.breadcrumbs {
  padding: 2.5rem 2.5rem 0;
  font-size: 1.44rem;
  font-weight: 300; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .breadcrumbs {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .breadcrumbs {
      font-size: 1.28rem; } }
  @media (min-width: 600px) {
    .breadcrumbs {
      padding: 2.5rem 5rem 0; } }

ul.category-list {
  list-style: disc;
  margin-left: 1.8rem; }
  ul.category-list li {
    margin-bottom: 0;
    font-weight: 300;
    color: #00B6BA; }
    ul.category-list li a {
      font-weight: 300;
      color: #00B6BA;
      text-decoration: none; }
      ul.category-list li a:hover {
        text-decoration: underline; }

.u-margin-top {
  margin-top: 2rem; }
  @media (min-width: 600px) {
    .u-margin-top {
      margin-top: 3.5rem; } }

.u-rule-top {
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding-top: 2rem; }
  @media (min-width: 600px) {
    .u-rule-top {
      margin-top: 3.5rem;
      padding-top: 3.5rem; } }

.u-margin-b {
  margin-bottom: 3.5rem; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .u-margin-b-1 {
    margin-bottom: 1rem; } }

@media (min-width: 600px) {
  .u-narrow {
    padding-left: 10%;
    padding-right: 10%; } }

.u-centred {
  text-align: center; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .u-hide-1 {
    display: none; } }

.header__top-bar {
  width: 100%;
  padding: 1.25rem 2.5rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 1200px) {
    .header__top-bar {
      padding: 1.25rem 5rem; } }
  .header__top-bar__section {
    display: flex;
    align-items: center; }
  .header__top-bar__phone {
    font-weight: 300;
    margin-right: 2rem; }
  .header__top-bar__icon {
    height: 2rem;
    width: auto;
    margin-bottom: -.6rem;
    margin-right: 1rem; }

.header__search {
  display: flex;
  align-items: center;
  padding-right: 1rem; }
  .header__search-input {
    display: inline-block;
    margin: 0;
    padding: .5rem 1.4rem;
    width: 20rem;
    font-size: 1.6rem;
    line-height: 1.5;
    border: none;
    border-radius: 8rem;
    background-color: white;
    color: #999; }
  .header__search-button {
    border: none;
    font-size: 1.6rem;
    text-decoration: none;
    cursor: pointer;
    padding: 0.75rem !important;
    background: transparent !important; }
    .header__search-button:hover {
      background-color: transparent !important; }
  .header__search-icon {
    display: block;
    height: 2.2rem;
    margin-bottom: -.7rem; }

.header__search-classes-button {
  border: none;
  background-color: #DB4034;
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 8rem;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }
  .header__search-classes-button:hover {
    background-color: #292929;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s; }
  @media (min-width: 900px) {
    .header__search-classes-button {
      margin-right: 1rem; } }

.header__lang-switch {
  display: inline-block;
  margin: 0 1.25rem; }
  .header__lang-switch a {
    color: white;
    text-decoration: none; }

.header__brand-bar {
  max-width: 144rem;
  margin: 0 auto;
  padding: 1.25rem 2.5rem;
  background-color: white;
  width: 100%;
  display: table; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .header__brand-bar {
      height: 8.5rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .header__brand-bar {
      height: 7.5rem; } }
  @media (min-width: 1200px) {
    .header__brand-bar {
      padding: 1.25rem 5rem; } }

.header__brand-bar__container {
  display: table-row; }

.header__brand-bar__container__l {
  display: table-cell;
  vertical-align: middle;
  text-align: left; }

.header__brand-bar__container__r {
  display: table-cell;
  vertical-align: middle;
  text-align: right; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .header__brand-bar__container__r {
      display: none; } }
  @media only screen and (max-width: 954px) {
    .header__brand-bar__container__r {
      padding-right: 6rem; } }

.header__brand-bar__logo {
  height: 5rem;
  width: auto; }
  @media (min-width: 600px) {
    .header__brand-bar__logo {
      height: 6rem;
      width: auto; } }
  @media (min-width: 900px) {
    .header__brand-bar__logo {
      height: 7.5rem;
      width: auto;
      margin-right: 2.5rem; } }

.header__brand-bar__endorsement {
  max-width: 30rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.35;
  color: white; }

.header__brand-bar__seal {
  width: 10rem;
  display: block;
  float: right; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .header__brand-bar__seal {
      width: 8rem;
      height: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .header__brand-bar__seal {
      width: 7rem;
      height: auto; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .header__brand-bar__seal {
      width: 6rem;
      height: auto; } }

@media only screen and (min-width: 955px) {
  .mob {
    display: none; }
  .overlay {
    z-index: 3;
    position: relative;
    max-width: 144rem;
    margin: 0 auto; }
  .overlay ul {
    clear: both;
    display: block;
    background-color: white;
    list-style: none;
    padding: 0 2.5rem;
    margin: 0 0 0 0; } }
  @media only screen and (min-width: 955px) and (min-width: 1200px) {
    .overlay ul {
      padding: 0 5rem; } }

@media only screen and (min-width: 955px) {
  .overlay ul li {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    margin-right: 16px; } }
  @media only screen and (min-width: 955px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .overlay ul li {
      margin-bottom: 1.2rem; } }

@media only screen and (min-width: 955px) {
    .overlay ul li a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      color: #5A5A5A;
      text-decoration: none;
      font-weight: 200;
      padding-bottom: 10px; }
    .overlay ul li:hover a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      color: #5A5A5A; }
    .overlay ul li:hover ul {
      display: block; }
    .overlay ul li.dropdown::after {
      content: "\25BE";
      color: #5A5A5A;
      margin-left: .3rem; }
  .overlay ul li ul {
    display: none;
    position: absolute;
    min-width: 250px;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 10px; }
    .overlay ul li ul:hover ul {
      display: block; }
  .overlay ul li ul li {
    display: block;
    float: none;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0; }
    .overlay ul li ul li a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      display: block;
      padding: 0.96rem 1.2rem;
      background-color: white;
      border-bottom: 1px solid #f4f4f4; }
    .overlay ul li ul li:hover a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      background-color: #f4f4f4; } }

@media only screen and (max-width: 954px) {
  .dtop {
    display: none; }
  .button_container {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
    height: 4rem;
    width: 4rem;
    background-color: #DB4034; }
    .button_container.active .top {
      transform: translateY(8px) translateX(0) rotate(45deg); }
    .button_container.active .middle {
      opacity: 0; }
    .button_container.active .bottom {
      transform: translateY(-8px) translateX(0) rotate(-45deg); }
    .button_container span {
      background-color: white;
      border: none;
      height: 3px;
      width: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      transition: all .35s ease;
      cursor: pointer; }
      .button_container span:nth-of-type(2) {
        top: 18px; }
      .button_container span:nth-of-type(3) {
        top: 26px; }
  .overlay {
    z-index: 3;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
    overflow-y: scroll; }
    .overlay.open {
      opacity: 1;
      visibility: visible;
      height: 100%; }
      .overlay.open li {
        animation: fadeInRight .5s ease forwards;
        animation-delay: .35s; }
        .overlay.open li:nth-of-type(2) {
          animation-delay: .4s; }
        .overlay.open li:nth-of-type(3) {
          animation-delay: .45s; }
        .overlay.open li:nth-of-type(4) {
          animation-delay: .50s; }
        .overlay.open li:nth-of-type(5) {
          animation-delay: .55s; }
        .overlay.open li:nth-of-type(6) {
          animation-delay: .60s; }
        .overlay.open li:nth-of-type(7) {
          animation-delay: .65s; }
        .overlay.open li:nth-of-type(8) {
          animation-delay: .70s; }
        .overlay.open li:nth-of-type(9) {
          animation-delay: .75s; }
        .overlay.open li:nth-of-type(10) {
          animation-delay: .80s; }
    .overlay nav {
      position: relative;
      font-size: 18px;
      padding: 18px; }
    .overlay ul {
      list-style: none;
      padding: 0;
      margin-left: 0;
      display: block;
      position: relative;
      height: 100%; }
      .overlay ul li {
        display: block;
        position: relative;
        opacity: 0; }
        .overlay ul li a {
          display: block;
          position: relative;
          color: #FE691D;
          text-decoration: none;
          overflow: hidden;
          padding: 0 0 5px; }
          .overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
            width: 100%; }
        .overlay ul li ul {
          max-height: 0;
          overflow: hidden;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          transition: all 0.5s ease;
          background: rgba(255, 255, 255, 0.5);
          margin: 0 -40px;
          padding: 0 40px; }
          .overlay ul li ul li a {
            color: #999;
            padding-left: 1rem; }
        .overlay ul li:hover ul {
          max-height: 300px;
          display: block;
          padding: 5px 40px; }
    .overlay .mobile-menu-extras {
      position: fixed;
      left: 2.5rem;
      right: 0;
      bottom: 0;
      padding-bottom: 2rem;
      padding-right: 2.5rem;
      color: #5A5A5A; }
      .overlay .mobile-menu-extras__locale-switch {
        border-radius: 8rem;
        background-color: #f4f4f4;
        margin: 1rem 0;
        text-align: center;
        line-height: 1.2;
        padding: 1rem 2rem;
        color: silver; }
        .overlay .mobile-menu-extras__locale-switch a {
          text-decoration: none; } }
    @media only screen and (max-width: 954px) and (min-width: 320px) and (max-width: 480px) {
      .overlay .mobile-menu-extras .mobile-menu-extras__social-mob {
        margin-top: 1rem;
        text-align: center; } }
    @media only screen and (max-width: 954px) and (min-width: 600px) {
      .overlay .mobile-menu-extras .mobile-menu-extras__social-mob {
        display: inline-block;
        margin-left: 2rem; } }

@media only screen and (max-width: 954px) {
      .overlay .mobile-menu-extras .mobile-menu-extras__social-mob a {
        display: inline-block; }
        .overlay .mobile-menu-extras .mobile-menu-extras__social-mob a:not(:last-child) {
          margin-right: 1rem; }
      .overlay .mobile-menu-extras .mobile-menu-extras__social-mob img {
        height: 2.75rem; } }
      @media only screen and (max-width: 954px) and (min-width: 600px) {
        .overlay .mobile-menu-extras .mobile-menu-extras__social-mob img {
          margin-bottom: -.6rem; } }
    @media only screen and (max-width: 954px) and (min-width: 320px) and (max-width: 480px) {
      .overlay .mobile-menu-extras .button--cta {
        width: 100%;
        text-align: center; } }

@media only screen and (max-width: 954px) {
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20px; }
    100% {
      opacity: 1;
      left: 0; } } }

.footer {
  clear: both;
  overflow-y: auto;
  padding: 5rem;
  color: #5A5A5A; }
  .footer a {
    color: #5A5A5A;
    text-decoration: none;
    transition: color 300ms ease; }
    .footer a:hover {
      color: #5A5A5A; }
  .footer li {
    margin-bottom: .4rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .footer {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .footer {
      padding: 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .footer {
      padding: 2.5rem; } }
  .footer__menu {
    float: left;
    width: 70%; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .footer__menu {
        float: none;
        width: 100%; } }
  .footer__menu-list {
    list-style: none;
    margin-left: 0; }
  .footer__menu-list--bold a {
    font-weight: 500; }
  .footer__contact {
    float: right;
    width: 30%;
    padding-left: 5rem; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .footer__contact {
        float: none;
        width: 100%;
        padding-left: 0; } }
  .footer__cervantes-seal {
    margin: 1rem 0;
    height: 12rem; }

.footer-bar {
  clear: both;
  overflow-y: auto;
  padding: 5rem;
  background-color: #292929;
  color: #AAAAAA; }
  .footer-bar a {
    color: #AAAAAA;
    text-decoration: none; }
    .footer-bar a:hover {
      color: white; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .footer-bar {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .footer-bar {
      padding: 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .footer-bar {
      padding: 2.5rem; } }
  .footer-bar__icon {
    height: 3.2rem;
    width: auto;
    margin-left: 1.5rem; }
  .footer-bar__legal {
    float: left;
    width: 70%; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .footer-bar__legal {
        float: none;
        width: 100%; } }
  .footer-bar__social {
    float: right;
    width: 30%;
    text-align: right;
    padding-left: 3.75rem; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .footer-bar__social {
        float: none;
        width: 100%;
        padding-left: 0;
        text-align: left; } }

@media (min-width: 600px) {
  .swlife-grid {
    display: flex;
    justify-content: center; } }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .swlife-grid__list:not(:last-child), .swlife-grid .card:not(:last-child) {
    margin-bottom: 2rem; } }

@media (min-width: 600px) {
  .swlife-grid__list, .swlife-grid .card {
    flex: 1;
    max-width: 40%; }
    .swlife-grid__list:not(:last-child), .swlife-grid .card:not(:last-child) {
      margin-right: 3.5rem; } }

.swlife-grid__list-title {
  padding: .75rem 1.2rem;
  color: white;
  margin-bottom: .3rem; }

.swlife-grid__list-footer {
  padding: 2rem 1rem;
  text-align: center; }

.form {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background: white;
  padding: 2.5rem;
  overflow-y: auto; }
  .form .tab {
    float: left;
    clear: none;
    text-align: inherit;
    width: 47.5%;
    margin-left: 0%;
    margin-right: 5%; }
    .form .tab::after {
      content: '';
      display: table;
      clear: both; }
    .form .tab:nth-child(n) {
      margin-right: 5%;
      float: left;
      clear: none; }
    .form .tab:nth-child(2n) {
      margin-right: 0%;
      float: right; }
    .form .tab:nth-child(2n + 1) {
      clear: both; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form .tab {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .form .tab:first-child {
        margin-left: auto; }
      .form .tab:last-child {
        margin-right: auto; }
    .form .cols--2 {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 3rem; }
      .form .cols--2:first-child {
        margin-left: auto; }
      .form .cols--2:last-child {
        margin-right: auto; }
      .form .cols--2:first-of-type {
        padding-top: 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form {
      padding: 1.5rem;
      margin: 0 -1rem; }
      .form .tab {
        display: block;
        clear: both;
        float: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
        .form .tab:first-child {
          margin-left: auto; }
        .form .tab:last-child {
          margin-right: auto; }
      .form .cols--2 {
        display: block;
        clear: both;
        float: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3rem; }
        .form .cols--2:first-child {
          margin-left: auto; }
        .form .cols--2:last-child {
          margin-right: auto; }
        .form .cols--2:first-of-type {
          padding-top: 0; } }

.form--enrol .tab, .form--book-event .tab {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .form--enrol .tab:first-child, .form--book-event .tab:first-child {
    margin-left: auto; }
  .form--enrol .tab:last-child, .form--book-event .tab:last-child {
    margin-right: auto; }

div.fields {
  overflow-y: auto; }

.required label::after {
  content: "*";
  color: red; }

div.submit {
  text-align: right;
  clear: both; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    div.submit {
      text-align: left; } }

fieldset {
  border: none;
  background: transparent;
  margin-top: 2.5rem; }
  fieldset:last-of-type {
    margin-bottom: 5rem; }

legend {
  font-weight: 500;
  color: #00B6BA; }

label {
  font-weight: 300; }

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form textarea {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  padding: 1rem 1.25rem;
  border-radius: 0;
  border: none;
  background-color: #f4f4f4;
  width: 100%;
  margin: .5rem 0 1.3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form input[type='text'],
    .form input[type='email'],
    .form input[type='password'],
    .form textarea {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form input[type='text'],
    .form input[type='email'],
    .form input[type='password'],
    .form textarea {
      font-size: 1.28rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form input[type='text'],
    .form input[type='email'],
    .form input[type='password'],
    .form textarea {
      font-size: 1.44rem; } }

.form input[type='number'] {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  padding: 1rem 0.75rem 1rem 1.25rem;
  border-radius: 0;
  border: none;
  background-color: #f4f4f4;
  margin: .5rem 0 1.3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form input[type='number'] {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form input[type='number'] {
      font-size: 1.28rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form input[type='number'] {
      font-size: 1.44rem; } }

.form input[type='submit'],
.form button[type='submit'] {
  border: none;
  background-color: #00B6BA;
  color: white;
  cursor: pointer;
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  padding: 1.6666666667rem 3.3333333333rem;
  border-radius: 8rem;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }
  .form input[type='submit']:hover,
  .form button[type='submit']:hover {
    background-color: #292929;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form input[type='submit'],
    .form button[type='submit'] {
      font-size: 1.4rem;
      padding: 1.25rem 2.5rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form input[type='submit'],
    .form button[type='submit'] {
      font-size: 1.28rem;
      padding: 1.25rem 2.5rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form input[type='submit'],
    .form button[type='submit'] {
      font-size: 1.6rem;
      padding: 0.8333333333rem 1.6666666667rem; } }

.form input[type="file"],
.form ::-webkit-file-upload-button {
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  margin: .5rem 0;
  border-radius: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form input[type="file"],
    .form ::-webkit-file-upload-button {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form input[type="file"],
    .form ::-webkit-file-upload-button {
      font-size: 1.28rem; } }

.form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  max-width: 100%;
  font-size: 1.6rem;
  height: 3.4rem;
  padding: .5rem 5rem .5rem 1rem;
  border: none;
  background-color: #f4f4f4;
  margin: .5rem 0 1.3rem;
  background-image: url("../assets/ui/ui-dropdown-arrow.png");
  background-repeat: no-repeat;
  background-position: right; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form select {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form select {
      font-size: 1.28rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form select {
      font-size: 1.44rem;
      max-width: 100%; } }

.form input[type=radio], .form input[type=checkbox] {
  display: none; }

.form input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: middle;
  margin: 0 8px 2px 0;
  background-color: white;
  border: 4px solid white;
  border-radius: 50%;
  cursor: pointer; }

.form input[type=radio]:checked + label:before {
  content: "";
  color: white;
  background-color: #00B6BA;
  border: 4px solid white;
  font-size: 1.35em;
  text-align: center;
  line-height: 18px; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .form input[type=radio]:checked + label:before {
      font-size: 1.4rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .form input[type=radio]:checked + label:before {
      font-size: 1.28rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .form input[type=radio]:checked + label:before {
      font-size: 1.44rem; } }

.form input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: middle;
  margin: 0 8px 2px 0;
  background-color: white;
  border-radius: 3px;
  padding: 2px;
  cursor: pointer; }

.form input[type=checkbox]:checked + label:before {
  content: "\2714";
  /* Tick */
  color: white;
  background-color: #00B6BA;
  text-align: center;
  line-height: 16px; }

.app-container {
  max-width: 144rem;
  margin: 0 auto;
  background-color: white; }

.container {
  clear: both;
  overflow-y: auto;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container {
      padding: 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container {
      padding: 2.5rem; } }
  .container__header {
    margin-bottom: 2rem; }
    @media (min-width: 600px) {
      .container__header {
        margin-bottom: 3.5rem; } }
    @media (min-width: 900px) {
      .container__header {
        margin-bottom: 5rem; } }
  .container__section {
    margin-top: 2.5rem; }

.container--narrow {
  padding: 5rem 20rem;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container--narrow {
      padding: 7.5rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container--narrow {
      padding: 5rem 10rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container--narrow {
      padding: 2.5rem;
      text-align: left; } }

.container--form {
  background-color: #f4f4f4;
  overflow-y: auto; }

.container__sidebar-l {
  float: left;
  width: 24%;
  padding: 5rem 0 5rem 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container__sidebar-l {
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container__sidebar-l {
      width: 30%;
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__sidebar-l {
      float: none;
      width: 100%;
      padding: 2.5rem 2.5rem 0; } }

.container__sidebar-r {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container__sidebar-r {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container__sidebar-r {
      width: 30%;
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__sidebar-r {
      float: none;
      width: 100%;
      padding: 0 2.5rem 2.5rem; } }

.container__sidebar-section {
  margin-bottom: 3.75rem; }

.container__main {
  float: left;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container__main {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container__main {
      padding: 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__main {
      padding: 2.5rem;
      float: none; } }

.container__main--sidebarx1 {
  width: 76%; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container__main--sidebarx1 {
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__main--sidebarx1 {
      padding: 2.5rem;
      float: none;
      width: 100%; } }

.container__main--sidebarx2 {
  width: 52%; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .container__main--sidebarx2 {
      width: 40%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__main--sidebarx2 {
      padding: 2.5rem;
      float: none;
      width: 100%; } }

.container__title {
  text-align: center;
  margin-bottom: 3.75rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__title {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .container__title {
      text-align: left; } }

.container__row {
  overflow-y: auto;
  padding-top: 5rem; }
  .container__row:first-of-type {
    padding-top: 0; }

.container--force-centre {
  text-align: center; }

.cols--2 {
  float: left;
  clear: none;
  text-align: inherit;
  width: 47.5%;
  margin-left: 0%;
  margin-right: 5%;
  margin-top: 5rem; }
  .cols--2::after {
    content: '';
    display: table;
    clear: both; }
  .cols--2:nth-child(n) {
    margin-right: 5%;
    float: left;
    clear: none; }
  .cols--2:nth-child(2n) {
    margin-right: 0%;
    float: right; }
  .cols--2:nth-child(2n + 1) {
    clear: both; }
  .cols--2:nth-child(-n+2) {
    margin-top: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .cols--2 {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0; }
      .cols--2:first-child {
        margin-left: auto; }
      .cols--2:last-child {
        margin-right: auto; } }

.cols--3 {
  float: left;
  clear: none;
  text-align: inherit;
  width: 30.6666666667%;
  margin-left: 0%;
  margin-right: 4%;
  margin-top: 5rem; }
  .cols--3::after {
    content: '';
    display: table;
    clear: both; }
  .cols--3:nth-child(n) {
    margin-right: 4%;
    float: left;
    clear: none; }
  .cols--3:nth-child(3n) {
    margin-right: 0%;
    float: right; }
  .cols--3:nth-child(3n + 1) {
    clear: both; }
  .cols--3:nth-child(-n+3) {
    margin-top: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .cols--3 {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0; }
      .cols--3:first-child {
        margin-left: auto; }
      .cols--3:last-child {
        margin-right: auto; } }

.cols--4 {
  float: left;
  clear: none;
  text-align: inherit;
  width: 22%;
  margin-left: 0%;
  margin-right: 4%;
  margin-top: 5rem; }
  .cols--4::after {
    content: '';
    display: table;
    clear: both; }
  .cols--4:nth-child(n) {
    margin-right: 4%;
    float: left;
    clear: none; }
  .cols--4:nth-child(4n) {
    margin-right: 0%;
    float: right; }
  .cols--4:nth-child(4n + 1) {
    clear: both; }
  .cols--4:nth-child(-n+4) {
    margin-top: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .cols--4 {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0; }
      .cols--4:first-child {
        margin-left: auto; }
      .cols--4:last-child {
        margin-right: auto; } }

.cols--icons-left {
  padding-left: 9rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .cols--icons-left {
      padding-left: 0; } }

.box > * {
  margin: 0; }

.box {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 1.6666666667rem;
  overflow: hidden; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .box {
      padding: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .box {
      padding: 1.25rem; } }

.cta-box {
  display: table; }

.cta-box__container {
  display: table-row; }

.cta-box__container-l {
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  text-align: center; }
  .cta-box__container-l img {
    max-width: 80%; }

.cta-box__container-m {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
  padding: 0 2rem; }

.cta-box__container-r {
  display: table-cell;
  vertical-align: middle;
  width: 30%;
  text-align: right; }

.button {
  background-color: #DB4034;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  color: white;
  text-decoration: none;
  border-radius: 6rem;
  transition: all 350ms ease;
  display: inline-block;
  cursor: pointer;
  border: none; }
  .button:hover {
    background-color: #292929; }
  .button--light {
    background-color: #AAAAAA; }
    .button--light:hover {
      background-color: #5e5d5d;
      color: white; }
  .button--inverse {
    background-color: white;
    color: #DB4034; }
    .button--inverse:hover {
      background-color: #DB4034;
      color: white; }
  .button--outline {
    border: 1px solid #DB4034;
    background-color: transparent;
    color: #DB4034; }
    .button--outline:hover {
      color: white;
      background-color: #DB4034; }
  .button--outline-light {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc !important; }
    .button--outline-light:hover {
      background-color: #ccc;
      color: white !important; }
  .button--slug {
    display: block;
    text-align: center;
    border-radius: 0; }
  .button--tag {
    margin-bottom: 1rem;
    background-color: #f4f4f4;
    color: #5A5A5A; }
    .button--tag:hover {
      background-color: #00B6BA;
      color: white; }
  .button--sml {
    padding: .5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 400; }
  .button--lrg {
    font-size: 2rem; }
  .button--xlrg {
    font-size: 2.5rem; }

.button-cta {
  z-index: 1;
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: rgba(218, 218, 218, 0.25);
  padding: 1rem 7.5rem 1rem 2.25rem;
  font-size: 1.6rem;
  text-decoration: none;
  color: #5A5A5A;
  border-radius: 6rem;
  transition: all 350ms ease; }
  .button-cta::before {
    content: url(../assets/ui/sw-icon.svg);
    position: absolute;
    top: calc(50% - 1rem);
    right: 1.5rem;
    width: 5rem;
    height: 2rem;
    transition: all 350ms ease; }
  .button-cta::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: calc(50% - .6rem);
    right: 1.5rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 2rem;
    background-color: transparent;
    transition: all 350ms ease; }
  .button-cta:hover {
    color: white; }
    .button-cta:hover::before {
      content: url(../assets/ui/sw-icon-inv.svg); }
    .button-cta:hover::after {
      content: '';
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 4rem;
      background-color: #DB4034;
      transition: all 350ms ease; }
  .button-cta--inverse {
    background-color: rgba(255, 255, 255, 0.75); }
  .button-cta--sml {
    padding: .5rem 5.25rem .5rem 1.5rem;
    font-size: 1.2rem; }
    .button-cta--sml::before {
      width: 3.5rem;
      height: 1.5rem;
      top: calc(50% -.75rem);
      right: 1rem; }
    .button-cta--sml::after {
      content: '';
      top: calc(50% - .4rem);
      right: 1rem;
      width: .8rem;
      height: .8rem; }
  .button-cta--lrg {
    font-size: 2rem; }
    .button-cta--lrg::before {
      top: calc(50% -1.4rem); }

.box-button {
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.3333333333%;
  margin-left: 0%;
  margin-right: 3%;
  position: relative;
  height: 18rem;
  overflow: hidden;
  border: none;
  background-color: #008487;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  margin-bottom: 2rem; }
  .box-button::after {
    content: '';
    display: table;
    clear: both; }
  .box-button:nth-child(n) {
    margin-right: 3%;
    float: left;
    clear: none; }
  .box-button:nth-child(3n) {
    margin-right: 0%;
    float: right; }
  .box-button:nth-child(3n + 1) {
    clear: both; }
  .box-button:nth-child(-n+3) {
    margin-top: 0; }
  .box-button:hover {
    background-color: #009da1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .box-button {
      float: left;
      clear: none;
      text-align: inherit;
      width: 48.5%;
      margin-left: 0%;
      margin-right: 3%; }
      .box-button::after {
        content: '';
        display: table;
        clear: both; }
      .box-button:nth-child(n) {
        margin-right: 3%;
        float: left;
        clear: none; }
      .box-button:nth-child(2n) {
        margin-right: 0%;
        float: right; }
      .box-button:nth-child(2n + 1) {
        clear: both; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .box-button {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      height: 16rem; }
      .box-button:first-child {
        margin-left: auto; }
      .box-button:last-child {
        margin-right: auto; }
      .box-button:nth-child(-n+3) {
        margin-top: 0.5rem; } }

.box-button p {
  font-size: 1.92rem;
  font-weight: 300;
  margin-bottom: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .box-button p {
      font-size: 1.6rem; } }

.box-button--sml {
  height: 10rem; }
  .box-button--sml p {
    font-size: 1.6rem;
    font-weight: 300; }

.box-button__container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0.75rem 2rem;
  text-align: center; }
  .box-button__container * {
    line-height: 1.3; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .box-button__container {
      padding: 2.5rem; } }

.sidebar-module > * {
  margin: 0; }

.sidebar-module {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 1.6666666667rem;
  margin-bottom: 2.5rem;
  overflow: hidden; }
  .sidebar-module h4 {
    margin-bottom: .6rem; }
  .sidebar-module a.button {
    margin-top: 1rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .sidebar-module {
      padding: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .sidebar-module {
      padding: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .sidebar-module:last-of-type {
      margin-bottom: 0; } }

.title-bar {
  background-color: #00B6BA;
  padding: 1.5rem 2.5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .title-bar {
      padding: 1rem 1.5rem; } }

.title-bar > * {
  color: white;
  margin: 0; }

.title-bar--rounded {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .title-bar--rounded {
      padding: 1rem 1.5rem;
      margin: 0 -1rem; } }

.title-bar--centred {
  text-align: center; }

.sub-nav {
  max-width: 144rem;
  margin: 0 auto;
  overflow-y: auto;
  color: white;
  padding-top: 1rem; }

.sub-nav ul {
  list-style: none;
  margin-left: 0;
  padding: 0 2.5rem; }
  @media (min-width: 1200px) {
    .sub-nav ul {
      padding: 0 5rem; } }
  .sub-nav ul li {
    display: inline-block; }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .sub-nav ul li {
        margin-bottom: .2rem; } }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      .sub-nav ul li {
        margin-bottom: .2rem; } }
    .sub-nav ul li a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      color: white;
      text-decoration: none;
      font-weight: 200;
      margin-right: 1rem;
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba(255, 255, 255, 0); }
    .sub-nav ul li:hover a {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      color: white;
      border-bottom: 3px solid rgba(255, 255, 255, 0.6); }
    .sub-nav ul li.active a {
      border-bottom: 3px solid white; }

.banner-image {
  position: relative;
  background-color: #373c42;
  background-size: cover;
  background-position: center;
  overflow: hidden; }
  .banner-image__image {
    display: block;
    width: 100%; }
  .banner-image__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center;
    color: white; }
    .banner-image__overlay h1, .banner-image__overlay h2, .banner-image__overlay h3, .banner-image__overlay h4, .banner-image__overlay h5, .banner-image__overlay h6 {
      color: white; }
    .banner-image__overlay--letterbox {
      background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .banner-image__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: 5rem 25rem;
    text-align: center;
    color: white; }
    .banner-image__content h1, .banner-image__content h2, .banner-image__content h3, .banner-image__content h4, .banner-image__content h5, .banner-image__content h6 {
      color: white; }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      .banner-image__content {
        padding: 5rem 10rem; } }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      .banner-image__content {
        padding: 5rem 7.5rem; } }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .banner-image__content {
        padding: 2.5rem; } }
    .banner-image__content--shallow {
      top: 0;
      transform: none;
      text-align: left;
      padding: 2.5rem; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .banner-image--hide-mob-overlay .banner-image__overlay *,
  .banner-image--hide-mob-overlay .banner-image__content * {
    display: none; } }

.banner-image--hide-mob-overlay .banner-image__overlay.banner-image__overlay--letterbox {
  display: none; }

.category-card {
  float: left;
  clear: none;
  text-align: inherit;
  width: 23.5%;
  margin-left: 0%;
  margin-right: 2%;
  margin-top: 2.5rem;
  text-decoration: none;
  outline: none; }
  .category-card::after {
    content: '';
    display: table;
    clear: both; }
  .category-card:nth-child(n) {
    margin-right: 2%;
    float: left;
    clear: none; }
  .category-card:nth-child(4n) {
    margin-right: 0%;
    float: right; }
  .category-card:nth-child(4n + 1) {
    clear: both; }
  .category-card:nth-child(-n+4) {
    margin-top: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .category-card {
      float: left;
      clear: none;
      text-align: inherit;
      width: 49%;
      margin-left: 0%;
      margin-right: 2%; }
      .category-card::after {
        content: '';
        display: table;
        clear: both; }
      .category-card:nth-child(n) {
        margin-right: 2%;
        float: left;
        clear: none; }
      .category-card:nth-child(2n) {
        margin-right: 0%;
        float: right; }
      .category-card:nth-child(2n + 1) {
        clear: both; } }

.category-card:hover .category-card__title-bar {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #e74f01; }

.category-card__image {
  display: block;
  outline: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0; }

.category-card__title-bar {
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background-color: #FE691D;
  padding: 1.5rem;
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .category-card__title-bar {
      font-size: 100%; } }

.slider__button {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  width: 5rem;
  height: 5rem;
  border: 2px solid white;
  padding: 1rem;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: 1.5rem;
  line-height: 1; }
  .slider__button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .slider__button {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .slider__button {
      width: 4rem;
      height: 4rem; } }

.slider__button--prev {
  position: absolute;
  top: 35%;
  left: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .slider__button--prev {
      left: -1.75rem; } }

.slider__button--next {
  position: absolute;
  top: 35%;
  right: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .slider__button--next {
      right: -1.75rem; } }

.slider__button--primary {
  color: #00B6BA;
  border: 2px solid #00B6BA; }

.card-slider {
  padding: 0 9rem;
  position: relative;
  margin-bottom: 2.5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .card-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .card-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .card-slider {
      padding: 1rem 1.25rem;
      margin: 0 0 0 0; } }

.card-slider__slide {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0 1.6666666667rem;
  background-color: white; }

.card-slider__slide__title-bar {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background-color: #008e91;
  padding: 2.5rem;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .card-slider__slide__title-bar {
      padding: 1.875rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .card-slider__slide__title-bar {
      padding: 1.875rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .card-slider__slide__title-bar {
      padding: 1.25rem; } }

.card-slider__slide__title-bar > * {
  margin: 0; }

.card-slider__slide__content {
  padding: 2.5rem;
  color: #5A5A5A;
  text-align: center; }
  .card-slider__slide__content p {
    color: #5A5A5A; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .card-slider__slide__content {
      padding: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .card-slider__slide__content {
      padding: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .card-slider__slide__content {
      padding: 1.25rem; } }

.banner-slider {
  position: relative;
  z-index: 1; }
  .banner-slider__slide {
    position: relative; }
  .banner-slider__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem; }
    .banner-slider__overlay p, .banner-slider__overlay h1, .banner-slider__overlay h2, .banner-slider__overlay h3, .banner-slider__overlay h4, .banner-slider__overlay h5, .banner-slider__overlay h6 {
      color: white; }
    @media (min-width: 600px) {
      .banner-slider__overlay {
        padding: 3.5rem; } }
    @media (min-width: 900px) {
      .banner-slider__overlay {
        padding: 5rem 40% 5rem 5rem; }
        .banner-slider__overlay p {
          font-size: 2.4rem;
          margin-bottom: 3rem; }
        .banner-slider__overlay h1 {
          font-size: 6.4rem; }
        .banner-slider__overlay h2 {
          font-size: 5.6rem; }
        .banner-slider__overlay h3 {
          font-size: 4.4rem; }
        .banner-slider__overlay h4 {
          font-size: 3.2rem;
          font-weight: 400; }
        .banner-slider__overlay h5 {
          font-size: 2.8rem;
          font-weight: 400; }
        .banner-slider__overlay h6 {
          font-size: 2.16rem;
          font-weight: 500; } }
    .banner-slider__overlay--tinted {
      background-color: rgba(0, 0, 0, 0.25); }
    .banner-slider__overlay--centred {
      text-align: center; }
  .banner-slider__image {
    width: 100%; }
  .banner-slider__button {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    width: 5rem;
    height: 5rem;
    border: 2px solid white;
    padding: 1rem;
    cursor: pointer;
    outline: none;
    color: white;
    font-size: 1.5rem;
    line-height: 1; }
    .banner-slider__button:hover {
      background-color: rgba(0, 0, 0, 0.4);
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s; }
    .banner-slider__button--prev {
      position: absolute;
      z-index: 5;
      bottom: 2rem;
      left: 2rem; }
      @media (min-width: 600px) {
        .banner-slider__button--prev {
          bottom: 3.5rem;
          left: 3.5rem; } }
      @media (min-width: 900px) {
        .banner-slider__button--prev {
          bottom: 5rem;
          left: 5rem; } }
    .banner-slider__button--next {
      position: absolute;
      z-index: 5;
      bottom: 2rem;
      left: calc(2rem + 5rem + 1rem); }
      @media (min-width: 600px) {
        .banner-slider__button--next {
          bottom: 3.5rem;
          left: calc(3.5rem + 5rem + 1rem); } }
      @media (min-width: 900px) {
        .banner-slider__button--next {
          bottom: 5rem;
          left: calc(5rem + 5rem + 1rem); } }

.picture-grid {
  background: #008e91;
  overflow-y: auto; }

.picture-grid__item {
  position: relative;
  float: left;
  clear: none;
  text-align: inherit;
  width: 25%;
  margin-left: 0%;
  margin-right: 0%; }
  .picture-grid__item::after {
    content: '';
    display: table;
    clear: both; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .picture-grid__item {
      float: left;
      clear: none;
      text-align: inherit;
      width: 50%;
      margin-left: 0%;
      margin-right: 0%; }
      .picture-grid__item::after {
        content: '';
        display: table;
        clear: both; } }

.picture-grid__overlay {
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2.5rem;
  opacity: 0; }
  .picture-grid__overlay:hover {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 1;
    -webkit-transition-delay: .25s;
    -moz-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity; }

.picture-grid__overlay__content {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  color: white; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .picture-grid__overlay__content {
      font-size: 90%; }
      .picture-grid__overlay__content .button {
        display: inline-block; } }

.picture-grid__image {
  display: block;
  width: 100%; }

.picture-grid__popup__thumbnail {
  float: right;
  width: 15rem;
  margin: 0 0 2.5rem 2.5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .picture-grid__popup__thumbnail {
      float: none;
      width: 12rem;
      margin: 0 0 1rem 0; } }

.popup {
  position: relative;
  padding: 3.5rem;
  width: auto;
  max-width: 60rem;
  margin: 2.5rem auto; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .popup {
      padding: 3.1875rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .popup {
      padding: 3.1875rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .popup {
      padding: 2.125rem; } }

.popup--white {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: #FFF;
  overflow-y: auto; }

.mfp-with-fade .mfp-content,
.mfp-with-fade .mfp-arrow, .mfp-with-fade.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.mfp-with-fade.mfp-ready .mfp-content,
.mfp-with-fade.mfp-ready .mfp-arrow {
  opacity: 1; }

.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-fade.mfp-removing .mfp-content,
.mfp-with-fade.mfp-removing .mfp-arrow, .mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0; }

.program__category-card {
  float: left;
  clear: none;
  text-align: inherit;
  width: 23.5%;
  margin-left: 0%;
  margin-right: 2%;
  margin-top: 2.5rem;
  text-decoration: none;
  outline: none; }
  .program__category-card::after {
    content: '';
    display: table;
    clear: both; }
  .program__category-card:nth-child(n) {
    margin-right: 2%;
    float: left;
    clear: none; }
  .program__category-card:nth-child(4n) {
    margin-right: 0%;
    float: right; }
  .program__category-card:nth-child(4n + 1) {
    clear: both; }
  .program__category-card:nth-child(-n+4) {
    margin-top: 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .program__category-card {
      float: left;
      clear: none;
      text-align: inherit;
      width: 32%;
      margin-left: 0%;
      margin-right: 2%; }
      .program__category-card::after {
        content: '';
        display: table;
        clear: both; }
      .program__category-card:nth-child(n) {
        margin-right: 2%;
        float: left;
        clear: none; }
      .program__category-card:nth-child(3n) {
        margin-right: 0%;
        float: right; }
      .program__category-card:nth-child(3n + 1) {
        clear: both; }
      .program__category-card:nth-child(-n+4) {
        margin-top: 2.5rem; }
      .program__category-card:nth-child(-n+3) {
        margin-top: 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .program__category-card {
      float: left;
      clear: none;
      text-align: inherit;
      width: 49%;
      margin-left: 0%;
      margin-right: 2%;
      margin-top: 0.625rem; }
      .program__category-card::after {
        content: '';
        display: table;
        clear: both; }
      .program__category-card:nth-child(n) {
        margin-right: 2%;
        float: left;
        clear: none; }
      .program__category-card:nth-child(2n) {
        margin-right: 0%;
        float: right; }
      .program__category-card:nth-child(2n + 1) {
        clear: both; }
      .program__category-card:nth-child(-n+4) {
        margin-top: 0.625rem; }
      .program__category-card:nth-child(-n+2) {
        margin-top: 0; } }

.program__category-card:hover .category-card__title-bar {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #008487; }

.program__category-card__image {
  display: block;
  outline: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0; }

.program__category-card__title-bar {
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background-color: #00B6BA;
  padding: 1.5rem;
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .program__category-card__title-bar {
      font-size: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .program__category-card__title-bar {
      font-size: 110%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .program__category-card__title-bar {
      font-size: 100%;
      padding: 1.25rem; } }

.program__sub-heading {
  font-weight: 500;
  margin: 2.5rem 0; }

.program__main-content {
  width: 52%;
  float: left;
  overflow-y: auto;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .program__main-content {
      float: right;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .program__main-content {
      width: 100%;
      float: none;
      padding: 2.5rem; } }

.program__sidebar-l {
  float: left;
  width: 24%;
  padding: 5rem 0 5rem 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .program__sidebar-l {
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .program__sidebar-l {
      width: 30%;
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .program__sidebar-l {
      display: none;
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.program__sidebar-r {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .program__sidebar-r {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .program__sidebar-r {
      width: 70%;
      padding: 0 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .program__sidebar-r {
      width: 100%;
      float: none;
      padding: 0 2.5rem 2.5rem; } }

.program__sidebar-section {
  margin-bottom: 3.75rem; }

.program__sidebar-list ul {
  margin: 0;
  margin-bottom: 1rem;
  list-style: none; }
  .program__sidebar-list ul li {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #5A5A5A; }
    .program__sidebar-list ul li a {
      font-weight: 500;
      color: #5A5A5A;
      text-decoration: none; }
      .program__sidebar-list ul li a:hover {
        text-decoration: underline; }
  .program__sidebar-list ul ul {
    list-style: disc;
    margin-left: 1.8rem; }
    .program__sidebar-list ul ul li {
      margin-bottom: 0;
      font-weight: 300;
      color: #00B6BA; }
      .program__sidebar-list ul ul li a {
        font-weight: 300;
        color: #00B6BA;
        text-decoration: none; }
        .program__sidebar-list ul ul li a:hover {
          text-decoration: underline; }

.program__summary {
  overflow-y: auto;
  margin: 2.5rem 0; }

.program__summary__item {
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%; }
  .program__summary__item::after {
    content: '';
    display: table;
    clear: both; }
  .program__summary__item:nth-child(n) {
    margin-right: 3%;
    float: left;
    clear: none; }
  .program__summary__item:nth-child(2n) {
    margin-right: 0%;
    float: right; }
  .program__summary__item:nth-child(2n + 1) {
    clear: both; }
  .program__summary__item p {
    margin-bottom: .2rem;
    color: silver; }

.program__module {
  margin-top: 2.5rem; }

.program__common-info {
  margin: 3.75rem 0; }

.exams__main-content {
  width: 52%;
  float: left;
  overflow-y: auto;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .exams__main-content {
      float: right;
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .exams__main-content {
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.exams__sidebar-l {
  float: left;
  width: 24%;
  padding: 5rem 0 5rem 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .exams__sidebar-l {
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .exams__sidebar-l {
      width: 30%;
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .exams__sidebar-l {
      display: none;
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.exams__sidebar-r {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .exams__sidebar-r {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .exams__sidebar-r {
      width: 70%;
      padding: 0 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .exams__sidebar-r {
      width: 100%;
      float: none;
      padding: 2.5rem; } }

.exams__sidebar-section {
  margin-bottom: 3.75rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .exams__sidebar-section {
      margin-bottom: 0; } }

.calendar__class {
  background-color: #f4f4f4;
  padding: 1rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-top: 1rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .calendar__class {
      margin-top: 0.75rem; }
      .calendar__class p {
        line-height: 1.4; } }

.calendar__class__title {
  margin-bottom: .2rem; }
  .calendar__class__title a {
    font-weight: 400;
    color: #5A5A5A; }
  .calendar__class__title--full {
    color: #DADADA; }

.calendar__class__time {
  margin-bottom: 0; }

.calendar__class__ref {
  margin-bottom: 0; }

.calendar__class__frequency {
  color: #AAAAAA; }

.calendar__class__cta {
  margin-top: .5rem; }

.calendar__class__badge {
  -webkit-border-radius: 1.6rem;
  -moz-border-radius: 1.6rem;
  border-radius: 1.6rem;
  display: inline-block;
  min-width: 3.52rem;
  text-align: center;
  padding: .1rem 1.25rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: white;
  background-color: #00B6BA; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .calendar__class__badge {
      font-size: 1.3rem;
      margin: 0.1rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .calendar__class__badge {
      font-size: 1.3rem;
      margin: 0.1rem 0; } }
  .calendar__class__badge--full {
    background-color: #DADADA; }

.calendar__class__enrol-btn {
  text-decoration: none;
  font-weight: 500;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-border-radius: 1.6rem;
  -moz-border-radius: 1.6rem;
  border-radius: 1.6rem;
  display: inline-block;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: .1rem 1rem .1rem 1.2rem;
  font-weight: 500;
  font-size: 1.4rem;
  background-color: #00B6BA; }
  .calendar__class__enrol-btn:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #292929; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .calendar__class__enrol-btn {
      font-size: 1.3rem;
      margin: 0.1rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .calendar__class__enrol-btn {
      font-size: 1.3rem;
      margin: 0.1rem 0; } }

.calendar--sidebar {
  margin-bottom: 2.5rem; }

.calendar__filter-toggle {
  display: none; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__filter-toggle {
      display: block;
      margin-bottom: 2rem; } }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .calendar__filter {
    overflow: hidden;
    height: 0;
    transition: all .5s ease; }
    .calendar__filter.open {
      height: auto; } }

.calendar__sidebar-button {
  display: block;
  background-color: #00B6BA;
  padding: 2rem;
  margin-bottom: .5rem;
  text-decoration: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }
  .calendar__sidebar-button * {
    color: white;
    margin: 0; }
  .calendar__sidebar-button:hover, .calendar__sidebar-button.here {
    background-color: #008e91; }
  .calendar__sidebar-button.here {
    background-image: url("/assets/images-ui/ui-icon-nav-arrow.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .calendar__sidebar-button.here {
        background-image: url("/assets/images-ui/ui-icon-here-tick.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: 30px; } }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .calendar__title {
    text-align: center; } }

.calendar__section-divider {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  background-color: #00B6BA;
  margin-bottom: 1.5rem; }
  .calendar__section-divider h1, .calendar__section-divider h2, .calendar__section-divider h3, .calendar__section-divider h4, .calendar__section-divider h5, .calendar__section-divider h6 {
    color: white;
    margin: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__section-divider {
      text-align: center; } }

.calendar__list-item {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid #f4f4f4;
  margin-bottom: 1.5rem; }

.calendar__list-item {
  display: table;
  width: 100%; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item {
      display: block; } }

.calendar__list-item__container {
  display: table-row; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__container {
      display: block; } }

.calendar__list-item__date,
.calendar__list-item__class,
.calendar__list-item__seats,
.calendar__list-item__details {
  display: table-cell;
  vertical-align: middle; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__date,
    .calendar__list-item__class,
    .calendar__list-item__seats,
    .calendar__list-item__details {
      display: block; } }

.calendar__list-item__container {
  display: table-row; }

.calendar__list-item__date {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
  background-color: #f4f4f4;
  padding: 1.5rem;
  text-align: center; }
  .calendar__list-item__date h1, .calendar__list-item__date h2, .calendar__list-item__date h3, .calendar__list-item__date h4, .calendar__list-item__date h5, .calendar__list-item__date h6 {
    margin: 0;
    line-height: 1; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__date {
      display: block;
      width: 100%;
      padding: 0.8333333333rem; }
      .calendar__list-item__date p {
        margin-bottom: 0; } }

.calendar__list-item__class {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
  padding: 1.5rem;
  background-color: white; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__class {
      display: block;
      width: 100%;
      padding-bottom: 0; }
      .calendar__list-item__class * {
        text-align: center; } }

.calendar__list-item__offer {
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  padding: 1rem;
  text-align: center; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__offer {
      display: block;
      width: 100%;
      padding: 0; }
      .calendar__list-item__offer img.special-offer__flash {
        width: 3rem; } }

.calendar__list-item__details {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
  padding: 1rem;
  text-align: right; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .calendar__list-item__details {
      display: block;
      width: 100%;
      padding-top: 0; } }

@media (min-width: 600px) {
  .class-calendar__filter {
    margin-bottom: 2rem; } }

.class-calendar__filter__button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 3.4rem;
  background-color: #f4f4f4;
  padding: .6rem 2rem;
  font-size: 1.6rem;
  text-decoration: none;
  color: black;
  transition: all 350ms ease; }
  .class-calendar__filter__button:hover {
    background-color: #cecece; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .class-calendar__filter__button {
      width: 100%; } }

.class-calendar__filter__options {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  max-width: 100%;
  font-size: 1.6rem;
  height: 3.4rem;
  padding: .5rem 5rem .5rem 1rem;
  border: none;
  background-color: #f4f4f4;
  background-image: url("/assets/ui/ui-dropdown-arrow.png");
  background-repeat: no-repeat;
  background-position: right; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .class-calendar__filter__options {
      width: 100%;
      margin-bottom: 1rem; } }

.class-calendar__form {
  margin: 0; }

@media (min-width: 600px) {
  .class-calendar__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0.2rem; } }

.class-calendar__grid__header {
  text-align: center;
  padding: 0 1rem 1rem;
  color: #999;
  font-weight: 300; }
  @media (min-width: 600px) {
    .class-calendar__grid__header {
      padding: 1rem;
      border-bottom: 3px solid #ddd;
      margin-bottom: .5rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .class-calendar__grid__header {
      font-size: 1.8rem;
      padding-top: 2rem; } }

.class-calendar__card {
  background-color: #b6b6b6; }
  .class-calendar__card:not(:last-child) {
    margin-bottom: 0.2rem; }
  .class-calendar__card__time {
    background-color: #494949;
    margin: 0;
    padding: 1rem;
    color: white;
    font-size: 1.2rem; }
  .class-calendar__card__details {
    padding: 1rem; }
  .class-calendar__card__title {
    margin: 0;
    font-size: 1.5rem;
    color: white;
    margin-bottom: .4rem; }
  .class-calendar__card__starts {
    font-size: 1.2rem;
    color: white;
    margin-bottom: .4rem; }
  .class-calendar__card__description {
    margin-bottom: .4rem;
    color: white;
    font-weight: 200;
    font-size: 1.2rem;
    line-height: 1.1; }
  .class-calendar__card__price {
    color: white;
    margin-bottom: .5rem;
    line-height: 1.1; }
    @media (min-width: 1200px) {
      .class-calendar__card__price {
        margin-bottom: 0 !important; } }
  .class-calendar__card__count {
    margin-bottom: .6rem;
    font-size: 1.4rem;
    line-height: 1.2;
    color: white; }
  @media (min-width: 1200px) {
    .class-calendar__card__cta {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .class-calendar__card__cta {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .class-calendar__card__button {
    background-color: rgba(255, 255, 255, 0.85);
    padding: .5rem 1.5rem;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    border-radius: 2rem;
    transition: all 350ms ease;
    display: inline-block;
    cursor: pointer;
    border: none; }
    .class-calendar__card__button:hover {
      background-color: white; }
  .class-calendar__card--full .class-calendar__card__time {
    color: #888; }
  .class-calendar__card--full .class-calendar__card__price {
    color: rgba(255, 255, 255, 0.4); }
  .class-calendar__card--full .class-calendar__card__button {
    background-color: rgba(255, 255, 255, 0.4);
    cursor: default; }
  .class-calendar__card--cat-1 {
    background-color: #00B6BA; }
  .class-calendar__card--cat-1 .class-calendar__card__button {
    color: #00B6BA; }
  .class-calendar__card--cat-2 {
    background-color: #FE691D; }
  .class-calendar__card--cat-2 .class-calendar__card__button {
    color: #FE691D; }
  .class-calendar__card--cat-3 {
    background-color: #2583cf; }
  .class-calendar__card--cat-3 .class-calendar__card__button {
    color: #2583cf; }
  .class-calendar__card--cat-4 {
    background-color: #7421a3; }
  .class-calendar__card--cat-4 .class-calendar__card__button {
    color: #7421a3; }
  .class-calendar__card--cat-5 {
    background-color: #9b0a63; }
  .class-calendar__card--cat-5 .class-calendar__card__button {
    color: #9b0a63; }
  .class-calendar__card--cat-6 {
    background-color: #89c912; }
  .class-calendar__card--cat-6 .class-calendar__card__button {
    color: #89c912; }

.class-calendar__error {
  padding: 2rem;
  text-align: center; }

.class-calendar__tilt-message {
  display: none; }
  @media (orientation: portrait) and (max-width: 790px) {
    .class-calendar__tilt-message {
      display: inline-block;
      margin: 1rem auto 0;
      padding: .5rem 0 .5rem 3.5rem;
      background-image: url("/assets/ui/icon-rotate-phone.svg");
      background-repeat: no-repeat;
      background-size: 2.4rem;
      background-position: center left; } }
  @media (orientation: landscape) and (max-width: 1200px) {
    .class-calendar__tilt-message {
      display: none; } }

.event-calendar__sidebar {
  float: left;
  width: 24%;
  padding: 5rem 0 5rem 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-calendar__sidebar {
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-calendar__sidebar {
      width: 30%;
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__sidebar {
      float: none;
      width: 100%;
      padding: 2.5rem 2.5rem 0; } }

.event-calendar__main-content {
  float: left;
  width: 76%;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-calendar__main-content {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-calendar__main-content {
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__main-content {
      float: none;
      width: 100%;
      padding: 2.5rem; } }

.event-calendar__section-divider {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  background-color: #AAAAAA;
  margin-bottom: 1.5rem; }
  .event-calendar__section-divider h1, .event-calendar__section-divider h2, .event-calendar__section-divider h3, .event-calendar__section-divider h4, .event-calendar__section-divider h5, .event-calendar__section-divider h6 {
    color: white;
    margin: 0; }

.event-calendar__list-item {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #008e91;
  margin-bottom: 1.5rem;
  overflow: hidden;
  color: white; }

.event-calendar__list-item__date {
  width: 20%;
  background-color: #DB4034;
  padding: 1.5rem;
  text-align: center; }
  .event-calendar__list-item__date h1, .event-calendar__list-item__date h2, .event-calendar__list-item__date h3, .event-calendar__list-item__date h4, .event-calendar__list-item__date h5, .event-calendar__list-item__date h6 {
    color: white;
    margin: 0;
    line-height: 1; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__date {
      width: 100%; } }

.event-calendar__list-item__class {
  width: 70%;
  padding: 1.5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__class {
      width: 100%;
      text-align: center; } }

.event-calendar__list-item__details {
  width: 10%;
  padding: 1.5rem;
  text-align: right; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__details {
      width: 100%;
      padding-top: 0;
      text-align: center; } }

.event-calendar__list-item {
  display: table;
  width: 100%; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item {
      display: block; } }

.event-calendar__list-item__container {
  display: table-row; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__container {
      display: block; } }

.event-calendar__list-item__date,
.event-calendar__list-item__class,
.event-calendar__list-item__seats,
.event-calendar__list-item__details {
  display: table-cell;
  vertical-align: middle; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__date,
    .event-calendar__list-item__class,
    .event-calendar__list-item__seats,
    .event-calendar__list-item__details {
      display: block; } }

.event-calendar__entry__main-content {
  float: left;
  width: 76%;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-calendar__entry__main-content {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-calendar__entry__main-content {
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__entry__main-content {
      float: none;
      width: 100%;
      padding: 2.5rem; } }

.event-calendar__entry__sidebar {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-calendar__entry__sidebar {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-calendar__entry__sidebar {
      width: 30%;
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__entry__sidebar {
      float: none;
      width: 100%;
      padding: 2.5rem; } }

.event-calendar__event__title {
  margin-bottom: .8rem; }
  .event-calendar__event__title a {
    font-weight: 400;
    color: white; }

.event-calendar__event__timing {
  color: #AAAAAA; }

.event-calendar__list-item__category {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid white;
  padding: .2rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-calendar__list-item__category {
      margin-bottom: 0; } }

.event-overview {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
  background-color: #008e91; }

.event-overview__date {
  padding: 2.5rem;
  background-color: #DB4034;
  color: white;
  text-align: center; }
  .event-overview__date h1, .event-overview__date h2, .event-overview__date h3, .event-overview__date h4, .event-overview__date h5, .event-overview__date h6 {
    color: white;
    margin: 0;
    line-height: 1; }

.event-overview__date__category {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  border: 2px solid white;
  padding: .4rem 1.5rem;
  margin-bottom: 1.75rem; }

.event-overview__details {
  padding: 2.5rem;
  color: white;
  text-align: center; }

.event-overview__details__label {
  text-transform: uppercase;
  font-weight: 400;
  color: #FE691D;
  font-size: 1.28rem; }

.event-overview__details__cost {
  margin-bottom: 2.5rem;
  color: white; }

.special-offer {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: 100%;
  display: table;
  margin-bottom: 2.5rem; }
  .special-offer__title-bar {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #DB4034;
    text-align: center;
    padding: 1rem; }
    .special-offer__title-bar-heading {
      color: white;
      margin: 0; }
  .special-offer__popup {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #00B6BA;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    float: left;
    clear: none;
    text-align: inherit;
    width: 49%;
    margin-left: 0%;
    margin-right: 2%; }
    .special-offer__popup::after {
      content: '';
      display: table;
      clear: both; }
    .special-offer__popup:nth-child(n) {
      margin-right: 2%;
      float: left;
      clear: none; }
    .special-offer__popup:nth-child(3n) {
      margin-right: 0%;
      float: right; }
    .special-offer__popup:nth-child(3n + 1) {
      clear: both; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .special-offer__popup {
        display: block;
        clear: both;
        float: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin: 1rem 0 0 0; }
        .special-offer__popup:first-child {
          margin-left: auto; }
        .special-offer__popup:last-child {
          margin-right: auto; } }
    .special-offer__popup-title {
      color: white;
      font-size: 2rem;
      margin-bottom: .4rem; }
  .special-offer__button {
    float: right;
    display: inline-block;
    border-radius: 2rem;
    background-color: #DB4034;
    padding: .3rem 1.2rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem; }
    .special-offer__button:hover {
      background-color: #292929;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s; }
  .special-offer__container {
    display: table-row; }
    .special-offer__container-l {
      display: table-cell;
      vertical-align: middle;
      padding: 1.25rem;
      width: 13rem;
      text-align: center; }
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        .special-offer__container-l {
          width: 8rem;
          vertical-align: top; } }
    .special-offer__container-r {
      display: table-cell;
      vertical-align: middle;
      padding: 1.25rem 1.25rem 1.25rem 0; }
  .special-offer__flash {
    min-width: 7rem; }
  .special-offer__tooltip {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 1.25rem 1.75rem;
    background-color: #008e91;
    color: white;
    text-align: left; }
  .special-offer__inline {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #DB4034;
    padding: .4rem 1rem;
    color: white; }
  .special-offer__text {
    color: #DB4034;
    font-weight: 400;
    margin-right: .5rem; }

.private-lessons {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: 100%;
  display: table;
  background-color: #f4f4f4;
  margin-bottom: 2.5rem; }

.private-lessons__container {
  display: table-row; }

.private-lessons__container-l {
  display: table-cell;
  vertical-align: middle;
  padding: 1.25rem;
  width: 13rem;
  text-align: center; }

.private-lessons__container-r {
  display: table-cell;
  vertical-align: middle;
  padding: 1.25rem 1.25rem 1.25rem 0; }

.private-lessons__icon {
  width: 7rem; }

.private-lessons__tooltip {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 1.25rem 1.75rem;
  background-color: #008e91;
  color: white;
  text-align: left; }

.swlife {
  z-index: 1;
  position: relative; }

.swlife--centred * {
  text-align: center; }

.swlife__button-container {
  z-index: 10;
  text-align: center; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .swlife__button-container {
      width: 100%;
      padding: 2.5rem 2.5rem 0; } }

.swlife__image-button {
  margin: -15rem .5rem .5rem;
  display: inline-block;
  max-width: 32rem;
  text-decoration: none;
  outline: none; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .swlife__image-button {
      margin-top: -10rem;
      max-width: 23rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .swlife__image-button {
      margin-top: -6rem;
      max-width: 20rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .swlife__image-button {
      max-width: 100%;
      width: 100%;
      margin: 1rem 0 0 0; }
      .swlife__image-button:first-of-type {
        margin: 0; } }

.swlife__image-button__image {
  display: block;
  outline: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0; }

.swlife__image-button__title-bar {
  border-radius: 0 0 0 0;
  background-color: #DB4034;
  padding: 1.5rem;
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200;
  transition: all 350ms ease; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .swlife__image-button__title-bar {
      padding: 1rem; } }
  .swlife__image-button__title-bar:hover {
    background-color: #FE691D; }

.photos__list-item {
  float: left;
  clear: none;
  text-align: inherit;
  width: 22%;
  margin-left: 0%;
  margin-right: 4%;
  margin-top: 5rem; }
  .photos__list-item::after {
    content: '';
    display: table;
    clear: both; }
  .photos__list-item:nth-child(n) {
    margin-right: 4%;
    float: left;
    clear: none; }
  .photos__list-item:nth-child(4n) {
    margin-right: 0%;
    float: right; }
  .photos__list-item:nth-child(4n + 1) {
    clear: both; }
  .photos__list-item:nth-child(-n+3) {
    margin-top: 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .photos__list-item {
      float: left;
      clear: none;
      text-align: inherit;
      width: 30.6666666667%;
      margin-left: 0%;
      margin-right: 4%; }
      .photos__list-item::after {
        content: '';
        display: table;
        clear: both; }
      .photos__list-item:nth-child(n) {
        margin-right: 4%;
        float: left;
        clear: none; }
      .photos__list-item:nth-child(3n) {
        margin-right: 0%;
        float: right; }
      .photos__list-item:nth-child(3n + 1) {
        clear: both; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .photos__list-item {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem; }
      .photos__list-item:first-child {
        margin-left: auto; }
      .photos__list-item:last-child {
        margin-right: auto; } }

.blog__card {
  float: left;
  clear: none;
  text-align: inherit;
  width: 30.6666666667%;
  margin-left: 0%;
  margin-right: 4%;
  margin-top: 5rem; }
  .blog__card::after {
    content: '';
    display: table;
    clear: both; }
  .blog__card:nth-child(n) {
    margin-right: 4%;
    float: left;
    clear: none; }
  .blog__card:nth-child(3n) {
    margin-right: 0%;
    float: right; }
  .blog__card:nth-child(3n + 1) {
    clear: both; }
  .blog__card:nth-child(-n+3) {
    margin-top: 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .blog__card {
      float: left;
      clear: none;
      text-align: inherit;
      width: 31.3333333333%;
      margin-left: 0%;
      margin-right: 3%;
      margin-top: 3.75rem; }
      .blog__card::after {
        content: '';
        display: table;
        clear: both; }
      .blog__card:nth-child(n) {
        margin-right: 3%;
        float: left;
        clear: none; }
      .blog__card:nth-child(3n) {
        margin-right: 0%;
        float: right; }
      .blog__card:nth-child(3n + 1) {
        clear: both; }
      .blog__card:nth-child(-n+3) {
        margin-top: 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .blog__card {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem; }
      .blog__card:first-child {
        margin-left: auto; }
      .blog__card:last-child {
        margin-right: auto; } }

.blog__img-sml {
  width: 100%;
  margin-bottom: 1rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.blog__main-content {
  float: left;
  width: 76%;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .blog__main-content {
      padding: 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .blog__main-content {
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .blog__main-content {
      float: none;
      width: 100%;
      padding: 2.5rem; } }

.blog__sidebar {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .blog__sidebar {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .blog__sidebar {
      width: 30%;
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .blog__sidebar {
      float: none;
      width: 100%;
      padding: 2.5rem;
      margin-top: 2.5rem;
      border-top: 1px solid #CFCFCF; } }

.blog__img-lrg {
  max-width: 100%;
  margin: 1rem 0 2rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.event-slider {
  padding: 0 9rem;
  position: relative;
  margin-bottom: 2.5rem;
  margin-left: 5rem;
  margin-right: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-slider {
      padding: 1rem 1.25rem;
      margin: 0 2.5rem; } }

.event-slider__slide {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0 1.6666666667rem;
  background-color: #008e91; }

.event-slider__slide__title-bar {
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background-color: #DB4034;
  padding: 2.5rem;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-slider__slide__title-bar {
      padding: 1.875rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-slider__slide__title-bar {
      padding: 1.875rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-slider__slide__title-bar {
      padding: 1.25rem; } }

.event-slider__slide__title-bar > * {
  margin: 0; }

.event-slider__slide__content {
  padding: 2.5rem;
  color: white;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .event-slider__slide__content {
      padding: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .event-slider__slide__content {
      padding: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .event-slider__slide__content {
      padding: 1.25rem; } }

.map-container {
  background: #f4f4f4;
  position: relative;
  overflow-y: auto;
  padding-bottom: 5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .map-container {
      padding-bottom: 0; } }

.map-container__sidebar-l {
  float: left;
  width: 24%;
  padding: 0 0 0 5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .map-container__sidebar-l {
      float: none;
      width: 100%;
      padding: 0 2.5rem; } }

.map-container__map {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 5rem;
  bottom: 5rem;
  width: calc(100% - (24% + 5rem + 5rem));
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: white; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .map-container__map {
      right: 3.75rem;
      bottom: 3.75rem;
      width: calc(100% - (24% + 3.75rem + 3.75rem)); } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .map-container__map {
      position: relative;
      width: 100%;
      height: 60vh;
      margin: 0;
      right: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; } }

.video__list-item {
  float: left;
  clear: none;
  text-align: inherit;
  width: 30.6666666667%;
  margin-left: 0%;
  margin-right: 4%;
  margin-top: 5rem; }
  .video__list-item::after {
    content: '';
    display: table;
    clear: both; }
  .video__list-item:nth-child(n) {
    margin-right: 4%;
    float: left;
    clear: none; }
  .video__list-item:nth-child(3n) {
    margin-right: 0%;
    float: right; }
  .video__list-item:nth-child(3n + 1) {
    clear: both; }
  .video__list-item:nth-child(-n+3) {
    margin-top: 0; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .video__list-item {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem; }
      .video__list-item:first-child {
        margin-left: auto; }
      .video__list-item:last-child {
        margin-right: auto; } }

.video__wrapper {
  margin: 0 auto 1.5rem;
  overflow-y: auto;
  max-width: 100%; }
  .video__wrapper .video__container {
    position: relative;
    padding-bottom: 56.5%;
    padding-top: 0px;
    height: 0;
    overflow: hidden; }
    .video__wrapper .video__container iframe, .video__wrapper .video__container object, .video__wrapper .video__container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.quote-slider {
  padding: 0 5rem;
  position: relative;
  margin-bottom: 2.5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .quote-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .quote-slider {
      margin-bottom: 1.25rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .quote-slider {
      padding: 1rem 1.25rem;
      margin: 0 0 0 0; } }
  .quote-slider__card {
    text-align: center;
    padding: 0 2rem; }
    .quote-slider__card-image {
      margin: 0 auto 2rem; }
  .quote-slider__category {
    font-weight: 500;
    margin-bottom: 2rem; }

.quotes__item:not(:last-child) {
  padding-bottom: 3.5rem;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 3.5rem; }

@media (min-width: 600px) {
  .quotes__item {
    display: flex; } }

.quotes__sidebar {
  min-width: 20rem; }

.summer__main-content {
  width: 52%;
  float: left;
  overflow-y: auto;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .summer__main-content {
      float: right;
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .summer__main-content {
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.summer__calendar-content {
  width: 76%;
  float: left;
  overflow-y: auto;
  padding: 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .summer__calendar-content {
      float: right;
      padding: 3.75rem;
      width: 70%; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .summer__calendar-content {
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.summer__sidebar-l {
  float: left;
  width: 24%;
  padding: 5rem 0 5rem 5rem; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .summer__sidebar-l {
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .summer__sidebar-l {
      width: 30%;
      padding: 3.75rem 0 3.75rem 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .summer__sidebar-l {
      width: 100%;
      float: none;
      padding: 2.5rem 2.5rem 0; } }

.summer__sidebar-r {
  float: right;
  width: 24%;
  padding: 5rem 5rem 5rem 0; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .summer__sidebar-r {
      padding: 3.75rem 3.75rem 3.75rem 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .summer__sidebar-r {
      width: 70%;
      padding: 0 3.75rem; } }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .summer__sidebar-r {
      width: 100%;
      float: none;
      padding: 2.5rem; } }

.summer__sidebar-section {
  margin-bottom: 3.75rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .summer__sidebar-section {
      margin-bottom: 0; } }

.summer__menu-button {
  display: block;
  color: white;
  background-color: #FE691D;
  padding: 2rem;
  margin-bottom: .5rem;
  text-decoration: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }
  .summer__menu-button * {
    color: white;
    margin: 0; }
  .summer__menu-button:hover, .summer__menu-button.here {
    background-color: #008e91; }
  .summer__menu-button.here {
    background-image: url("/assets/images-ui/ui-icon-nav-arrow.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .summer__menu-button.here {
        background-image: url("/assets/images-ui/ui-icon-here-tick.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: 30px; } }

.summer__boxout {
  margin: 2.5rem 0;
  padding: 2.5rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.summer__intro {
  margin-bottom: 5rem; }

.summer__overview {
  margin-top: 5rem;
  margin-bottom: 1rem; }
  .summer__overview-title {
    background-color: #DB4034;
    padding: 1rem 2rem;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0; }
  .summer__overview-title-heading {
    color: white;
    margin: 0; }
  .summer__overview-content {
    background-color: #f4f4f4;
    padding: 2rem 3rem;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0; }
    .summer__overview-content::after {
      content: '';
      display: table;
      clear: both; }
  .summer__overview-description {
    float: left;
    width: 70%;
    padding-right: 3rem; }
  .summer__overview-details {
    float: right;
    width: 30%; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .summer__calendar-item-offers {
    margin-top: 1rem; } }

.summer__calendar-item-icon {
  height: 2.5rem;
  margin: .25rem; }

.summer__popup-link {
  text-decoration: none; }

.summer__popup-icon {
  width: 6rem; }

.summer__sidebar-offer {
  display: flex;
  align-items: center; }
  .summer__sidebar-offer-left {
    width: 5rem; }
  .summer__sidebar-offer-icon {
    width: 2.5rem;
    margin-right: 1.25rem; }
  .summer__sidebar-offer-title {
    font-size: 90%; }

.subscribe {
  clear: both;
  padding: 2rem;
  text-align: center; }
  @media (min-width: 600px) {
    .subscribe {
      padding: 5rem; } }
  .subscribe__heading {
    margin-bottom: 2rem; }
  .subscribe input[type='text'],
  .subscribe input[type='email'] {
    display: inline-block;
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    border: none;
    background-color: white;
    width: auto; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .subscribe input[type='text'],
      .subscribe input[type='email'] {
        display: block;
        width: 100%;
        margin-bottom: 1rem; } }
    @media (min-width: 600px) {
      .subscribe input[type='text'],
      .subscribe input[type='email'] {
        margin-right: 0.75rem; } }
    @media (min-width: 900px) {
      .subscribe input[type='text'],
      .subscribe input[type='email'] {
        font-size: 2rem; } }
  .subscribe input[type='submit'] {
    display: inline-block;
    border: none;
    background-color: #00B6BA;
    color: white;
    cursor: pointer;
    font-family: 'Montserrat', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    transition: all 300ms ease; }
    .subscribe input[type='submit']:hover {
      background-color: #292929; }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      .subscribe input[type='submit'] {
        display: block;
        width: 100%; } }
    @media (min-width: 900px) {
      .subscribe input[type='submit'] {
        font-size: 2rem; } }

.staff-profiles {
  padding: 2.5rem; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .staff-profiles {
      text-align: center; } }
  @media (min-width: 600px) {
    .staff-profiles {
      padding: 5rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  .staff-profiles__item {
    width: 20rem;
    display: inline-block;
    margin: 1rem;
    text-align: center; }
    @media (min-width: 1200px) {
      .staff-profiles__item {
        margin: 2rem; } }
  .staff-profiles__photo {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    transition: all 250ms ease; }
    .staff-profiles__photo:hover {
      transform: scale(1.05); }
    @media (min-width: 1200px) {
      .staff-profiles__photo {
        width: 20rem;
        height: 20rem; } }
    .staff-profiles__photo--inset {
      float: right;
      width: 10rem;
      height: 10rem;
      margin: 0 0 1rem 1rem; }
  .staff-profiles__title {
    margin-top: 1rem; }
  .staff-profiles__overlay {
    position: relative;
    padding: 4rem;
    width: auto;
    max-width: 60rem;
    margin: 2rem auto;
    background: #FFF;
    overflow-y: auto; }

.mfp-staff-profiles .mfp-content,
.mfp-staff-profiles .mfp-arrow, .mfp-staff-profiles.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  transition: opacity 0.3s ease-out; }

.mfp-staff-profiles.mfp-ready .mfp-content,
.mfp-staff-profiles.mfp-ready .mfp-arrow {
  opacity: 1; }

.mfp-staff-profiles.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-staff-profiles.mfp-removing .mfp-content,
.mfp-staff-profiles.mfp-removing .mfp-arrow, .mfp-staff-profiles.mfp-removing.mfp-bg {
  opacity: 0; }

.update-card {
  background-color: white;
  padding: 2rem;
  color: #5A5A5A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem; }
  @media (min-width: 600px) {
    .update-card {
      width: calc( (100% - 4rem) /3);
      margin-right: 2rem; }
      .update-card:nth-child(3n+3) {
        margin-right: 0; } }
  @media (min-width: 1200px) {
    .update-card {
      width: calc( (100% - 6rem) /4); }
      .update-card:nth-child(3n+3) {
        margin-right: 2rem; }
      .update-card:nth-child(4n+4) {
        margin-right: 0; } }
  .update-card__title {
    color: #DB4034 !important; }
  .update-card__overview {
    margin-bottom: 1.5rem; }

.card-grid {
  display: flex;
  flex-wrap: wrap; }

.card {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .card {
      margin-bottom: 2rem; } }
  .card__hero {
    display: block; }
  .card__content {
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .card__footer {
    padding-top: 1rem; }
  .card__title {
    color: #00B6BA; }
  .card__text {
    color: #5A5A5A; }

.list-card {
  background-color: white;
  text-decoration: none;
  transition: all 350ms ease;
  display: flex; }
  .list-card:not(:last-child) {
    margin-bottom: .3rem; }
  .list-card * {
    line-height: 1.2; }
  .list-card:hover {
    background-color: #f4f4f4; }
  .list-card aside {
    flex: 1;
    padding: .5rem;
    transition: all 350ms ease;
    display: flex;
    justify-content: center;
    align-items: center; }
  .list-card main {
    flex: 5;
    padding: 1rem; }
  .list-card__icon {
    width: 3rem;
    height: auto; }
  .list-card__title {
    color: #00B6BA;
    margin-bottom: .4rem; }
  .list-card__text {
    color: #5A5A5A;
    margin: 0; }
  .list-card__date {
    color: white;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1;
    margin: 0; }
    .list-card__date span {
      font-size: 4rem;
      font-weight: 200; }
  .list-card--event aside {
    background-color: #DB4034; }
  .list-card--event:hover aside {
    background-color: #bb2c21; }
  .list-card--video aside {
    background-color: #FE691D; }
  .list-card--video:hover aside {
    background-color: #e74f01; }
  .list-card--photos aside {
    background-color: #FDDC00; }
  .list-card--photos:hover aside {
    background-color: #f3d300; }

/* BEGIN Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* END Slider */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
