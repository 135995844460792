.program {

}

// index page
.program__category-card {
  @include column(1/4, $cycle:4, $gutter:2);
  margin-top: $space*(0.5);
  text-decoration: none;
  outline: none;
  &:nth-child(-n+4) {margin-top: 0;}
  @include ipad-p {
    @include column(1/3, $cycle:3, $gutter:2);
    &:nth-child(-n+4) {margin-top: $space*(0.5);}
    &:nth-child(-n+3) {margin-top: 0;}
  }
  @include mobile {
    @include column(1/2, $cycle:2, $gutter:2);
    margin-top: $space-mobile*(0.25);
    &:nth-child(-n+4) {margin-top: $space-mobile*(0.25);}
    &:nth-child(-n+2) {margin-top: 0;}
  }
}
.program__category-card:hover .category-card__title-bar {
  @include fade(.4s);
  background-color: darken($brand-01, 10%);
}


.program__category-card__image {
  display: block;
  outline: none;
  @include round-corners( $corner-radius*2 $corner-radius*2 0 0 );
}
.program__category-card__title-bar {
  @include fade(.8s);
  @include round-corners( 0 0 $corner-radius*2 $corner-radius*2 );
  background-color: $brand-01;
  padding: $space*(0.3);
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200;
  @include ipad-l { font-size: 100%; }
  @include ipad-p { font-size: 110%; }
  @include mobile { font-size: 100%; padding: $space-mobile*(0.5); }
}


// category page

.program__sub-heading {
  font-weight: 500;
  margin: $space/2 0;
}
.program__main-content {
  width: 100%-($sidebar-width*2);
  float: left;
	overflow-y: auto;
  padding: $space;
  @include ipad-p {
    float: right;
    width: 100%-($sidebar-width-tablet);
  }
  @include mobile { width: 100%; float: none; padding: $space-mobile; }
}

.program__sidebar-l {
  float: left;
	width: $sidebar-width;
  padding: $space 0 $space $space;
  @include ipad-l { padding: $space-tablet 0 $space-tablet $space-tablet; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet 0 $space-tablet $space-tablet;
  }
  @include mobile { display: none; width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
}
.program__sidebar-r {
  float: right;
	width:  $sidebar-width;
  padding: $space $space $space 0;
  @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
  @include ipad-p {
    width: 100%-($sidebar-width-tablet);
    padding: 0 $space-tablet;
  }
  @include mobile { width: 100%; float: none; padding: 0 $space-mobile $space-mobile; }
}
.program__sidebar-section {
  margin-bottom: $space*(0.75);
}





// entry page

// sidebar
.program__sidebar-list {
  // first level
  ul {
    margin: 0;
    margin-bottom: 1rem;
    list-style: none;
    li {
      margin-bottom: 1rem;
      font-weight: 500;
      color: $ui-text;
      a {
        font-weight: 500;
        color: $ui-text;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    // second level
    ul {
      list-style: disc;
      margin-left: $base-font-size +(.2);
      li {
        margin-bottom: 0;
        font-weight: $base-font-weight;
        color: $brand-01;
        a {
          font-weight: $base-font-weight;
          color: $brand-01;
          text-decoration: none;
          &:hover {text-decoration: underline;}
        }
      }
    }
  }
}

// main content
.program__summary {
  overflow-y: auto;
  margin: $space*(0.5) 0;
}
.program__summary__item {
  @include column(1/2, $cycle:2);
  p {
    margin-bottom:.2rem;
    color: lighten($ui-text, 40%);
  }
}
.program__module {
  margin-top: $space*(0.5);
}
.program__common-info {
  margin: $space*(0.75) 0;
}
