.list-card {

    background-color: white;
    text-decoration: none;
    &:not(:last-child) { margin-bottom: .3rem; }
    transition: all 350ms ease;
    display: flex;

    * { line-height: 1.2; }

    &:hover {
        background-color: $ui-wash;
    }


    aside {
        flex: 1;
        padding: .5rem;
        transition: all 350ms ease;
        display: flex;
        justify-content: center;
        align-items: center;   
    }
    main {
        flex: 5;
        padding: 1rem;
    }

    &__icon {
        width: 3rem;
        height: auto;
    }

    &__title {
        color: $brand-01;
        margin-bottom: .4rem;
    }
    &__text {
        color: $ui-text;
        margin: 0;
    }

    &__date {
        color: white;
        text-align: center;
        font-size: 1.2rem; 
        font-weight: 400;
        line-height: 1;
        margin: 0;
        span { 
            font-size: 4rem; 
            font-weight: 200; 
        }
    }

    &--event {
        aside { background-color: $brand-02; }
        &:hover {
            aside { background-color: darken($brand-02, 10%); }
        }
    }
    &--video {
        aside { background-color: $brand-03; }
        &:hover {
            aside { background-color: darken($brand-03, 10%); }
        }
    }
    &--photos {
        aside { background-color: $brand-04; }
        &:hover {
            aside { background-color: darken($brand-04, 2%); }
        }
    }

}