.app-container {
  max-width: $site-max-width;
  margin: 0 auto;
  background-color: white;
}


.container {
  clear: both;
	overflow-y: auto;
  padding: $space;
  @include ipad-l { padding: $space-tablet; }
  @include ipad-p { padding: $space-tablet; }
  @include mobile { padding: $space-mobile; }

  &__header {
    margin-bottom: $space-sml;
    @include tablet-portrait-up {
      margin-bottom: $space-med;
    }
    @include tablet-landscape-up {
      margin-bottom: $space-lrg;
    }
  }

  &__section {
    margin-top: $space/2;
  }
}

.container--narrow {
  padding: $space $space*4;
  text-align: center;
  @include ipad-l { padding: $space-tablet*2; }
  @include ipad-p { padding: $space $space*2; }
  @include mobile { padding: $space-mobile; text-align: left; }
}
.container--form {
  background-color: $ui-wash;
  overflow-y: auto;
}


.container__sidebar-l {
  float: left;
	width: $sidebar-width;
  padding: $space 0 $space $space;
  @include ipad-l { padding: $space-tablet 0 $space-tablet $space-tablet; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet 0 $space-tablet $space-tablet;
  }
  @include mobile {  float: none; width: 100%; padding: $space-mobile $space-mobile 0; }
}
.container__sidebar-r {
  float: right;
	width:  $sidebar-width;
  padding: $space $space $space 0;
  @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet $space-tablet $space-tablet 0;
  }
  @include mobile {  float: none; width: 100%; padding: 0 $space-mobile $space-mobile; }
}
.container__sidebar-section {
  margin-bottom: $space*(0.75);
}
.container__main {
  float: left;
  padding: $space;
  @include ipad-l { padding: $space-tablet; }
  @include ipad-p { padding: $space-tablet; }
  @include mobile { padding: $space-mobile; float: none; }
}
.container__main--sidebarx1 {
  width: 100%-($sidebar-width);
  @include ipad-p {
    width: 100%-($sidebar-width-tablet);
  }
  @include mobile { padding: $space-mobile; float: none; width: 100%; }
}
.container__main--sidebarx2 {
  width: 100%-($sidebar-width*2);
  @include ipad-p {
    width: 100%-($sidebar-width-tablet*2);
  }
  @include mobile { padding: $space-mobile; float: none; width: 100%; }
}
.container__title {
  text-align: center;
  margin-bottom: $space*(0.75);
  @include mobile { margin-bottom: $space-mobile*(0.5); }
  @include mobile { text-align: left; }
}
.container__row {
  overflow-y: auto;
  padding-top: $space;
  &:first-of-type { padding-top: 0 }
}

.container--force-centre {
  text-align: center;
}




.grid {
  //margin-top: $space;
}
.grid--tight {
  //margin-top: $space*(0.5);
}
.cols--2 {
  @include column(1/2, $gutter:5, $cycle:2);
  margin-top: $space;
  &:nth-child(-n+2) {margin-top: 0;}
  @include mobile { @include stack(); margin-top: 0; }
}
.cols--3 {
  @include column(1/3, $gutter:4, $cycle:3);
  margin-top: $space;
  &:nth-child(-n+3) {margin-top: 0;}
  @include mobile { @include stack(); margin-top: 0; }
}
.cols--4 {
  @include column(1/4, $gutter:4, $cycle:4);
  margin-top: $space;
  &:nth-child(-n+4) {margin-top: 0;}
  @include mobile { @include stack(); margin-top: 0; }
}

.cols--icons-left {
  padding-left: $side-icon-margin;
  @include mobile { padding-left: 0; }
}
