.swlife {
  z-index: 1;
  position: relative;
}

.swlife--centred * {
  text-align: center;
}

.swlife__button-container {
  z-index: 10;
  text-align: center;
  @include mobile{
    width: 100%; padding: $space-mobile $space-mobile 0;
  }
}

.swlife__image-button {
  margin: -15rem .5rem .5rem;
  display: inline-block;
  max-width: 32rem;
  text-decoration: none;
  outline: none;

  @include ipad-l {
    margin-top: -10rem;
    max-width: 23rem;
  }
  @include ipad-p {
    margin-top: -6rem;
    max-width: 20rem;
  }
  @include mobile {
    max-width: 100%; 
    width: 100%; 
    margin: 1rem 0 0 0;
    &:first-of-type { margin: 0; }
  }
}



.swlife__image-button__image {
  display: block;
  outline: none;
  @include round-corners( $corner-radius*2 $corner-radius*2 0 0 );
}
.swlife__image-button__title-bar {
  border-radius: 0 0 $corner-radius*2 $corner-radius*2;
  background-color: $brand-02;
  padding: $space*(0.3);
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 200;
  transition: all 350ms ease;
  @include mobile { padding: 1rem; }

  &:hover {
    background-color: $brand-03;
  }
}
