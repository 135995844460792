.picture-grid {
 background: $ui-dark;
 overflow-y: auto;
}

.picture-grid__item {
  position: relative;
  @include span(1/4);

  @include mobile { @include span(1/2) }
}
.picture-grid__overlay {
  @include fade(.8s);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: $space/2;
  opacity: 0;
  &:hover {
    @include fade(.4s);
    opacity: 1;
    -webkit-transition-delay: .25s;
    -moz-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
  }
}
.picture-grid__overlay__content {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  @include mobile {
    font-size: 90%;
    .button { display: inline-block; }
  }
}
.picture-grid__image {
  display: block;
  width: 100%;
}
.picture-grid__popup__thumbnail {
  float: right;
  width: 15rem;
  margin: 0 0 $space/2 $space/2;
  @include mobile { float: none; width: 12rem; margin: 0 0 1rem 0; }
}
