@mixin mobile {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin desktop-hd-up {
  @media (min-width: 1800px) { @content; }
}


@mixin mobile-landscape {
  @media (orientation: landscape) and (max-width: 1200px) { @content; }
}
@mixin mobile-portrait {
  @media (orientation: portrait) and (max-width: 790px) { @content; }
}


// legacy mixins ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––


@mixin fade ($params){
  -webkit-transition: all $params;
	-moz-transition: all $params;
	-o-transition: all $params;
	transition: all $params;
}

@mixin round-corners ($radius) {
  -webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin ipad-l {
  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
    @content;
  }
}

@mixin ipad-p {
  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (min-width : 320px) and (max-width : 480px) {
    @content;
  }
}


// end legacy mixins ––––––––––––––––––––––––––––––––––––––––––––––––––––––


