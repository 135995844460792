.title-bar {
  background-color: $brand-01;
  padding: $space*(0.3) $space*(0.5);
  @include mobile { padding: 1rem 1.5rem; }
}
.title-bar > * {
  color: white;
  margin: 0;
}
.title-bar--rounded {
  @include round-corners( $corner-radius*2 $corner-radius*2 0 0 );
  @include mobile { padding: 1rem 1.5rem; margin: 0 -1rem; }
}
.title-bar--centred {
  text-align: center;
}
