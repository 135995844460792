$button-radius: 6rem;
$button-bg: $brand-02;
$button-bg-accent: $brand-04;
$button-bg-hover: $ui-dark-grey;
$button-text: $ui-text;
$button-text-inverse: white;



.button {
    
    background-color: $button-bg;
    padding: 1rem 2rem;
    font-size: $base-font-size;
    color: $button-text-inverse;
    text-decoration: none;
    border-radius: $button-radius;
    transition: all 350ms ease;
    display: inline-block;
    cursor: pointer;
    border: none;

    &:hover {
        background-color: $button-bg-hover;
    }


    // colour modifiers

    &--light {
        background-color: $brand-05;
        // color: $button-text;
        &:hover {
            background-color: darken($brand-05, 30%);
            color: $button-text-inverse;
        }
    }

    &--inverse {
        background-color: $button-text-inverse;
        color: $button-bg;
        &:hover {
            background-color: $button-bg;
            color: $button-text-inverse;
        }
    }
    &--outline {
        border: 1px solid $button-bg;
        background-color: transparent;
        color: $button-bg;
        &:hover{
            color: $button-text-inverse;
            background-color: $button-bg;
        }
    }
    &--outline-light {
        border: 1px solid #ccc;
        background-color: transparent;
        color: #ccc !important;
        &:hover{
            background-color: #ccc;
            color: $button-text-inverse !important;
        }
    }

    &--slug {
        display: block;
        text-align: center;
        border-radius: 0;
    }

    &--tag {
        margin-bottom: 1rem;
        background-color: $ui-wash;
        color: $ui-text;
        &:hover {
            background-color: $brand-01;
            color: white;
        }
    }

    // size modifiers

    &--sml {
        padding: .5rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 400;
    }
    &--lrg {
        font-size: 2rem;
    }
    &--xlrg {
        font-size: 2.5rem;
    }

}


.button-cta {

    z-index: 1;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: rgba($brand-06, 0.25);
    padding: 1rem 7.5rem 1rem 2.25rem;
    font-size: $base-font-size;
    text-decoration: none;
    color: $button-text;
    border-radius: $button-radius;
    transition: all 350ms ease;

    &::before {
        content: url(../assets/ui/sw-icon.svg);
        position: absolute;
        top: calc(50% - 1rem);
        right: 1.5rem;
        width: 5rem;
        height: 2rem;
        transition: all 350ms ease;
    }
    &::after {
        content:'';
        z-index: -1;
        position: absolute;
        top: calc(50% - .6rem);
        right: 1.5rem;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 2rem;
        background-color: transparent;
        transition: all 350ms ease;
    }

    &:hover {
        color: white;
        &::before {
            content: url(../assets/ui/sw-icon-inv.svg);
        }
        &::after {
            content:'';
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 4rem;
            background-color: $brand-02;
            transition: all 350ms ease;
        }
    }


    // colour modifiers

    &--inverse {
        background-color: rgba(255, 255, 255, 0.75);
        // color: $button-text-inverse;
    }
 
    // size modifiers

    &--sml {
        padding: .5rem 5.25rem .5rem 1.5rem;
        font-size: 1.2rem;
        &::before {
            width: 3.5rem;
            height: 1.5rem;
            top: calc(50% -.75rem);
            right: 1rem;
        }
        &::after {
            content:'';
            top: calc(50% - .4rem);
            right: 1rem;
            width: .8rem;
            height: .8rem;
        }
    }

    &--lrg {
        font-size: 2rem;
        &::before {
            top: calc(50% -1.4rem);
        }
    }

}

