.sub-nav {

  max-width: $site-max-width;
  margin: 0 auto;

  // background-color: $brand-02;
  overflow-y: auto;
  color: white;
  padding-top: 1rem;

}

.sub-nav ul {
  list-style: none;
  margin-left: 0;
  padding: 0 $space*(0.5);

  @include desktop-up {
    padding: 0 $space;
  }

  li {
    display: inline-block;
    @include ipad-l {margin-bottom: .2rem;}
    @include ipad-p {margin-bottom: .2rem;}
    a {
      @include fade(.3s);
      color: white;
      text-decoration: none;
      font-weight: 200;
      margin-right: 1rem;
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba(255, 255, 255, 0);
    }
    &:hover {
      a {
        @include fade(.3s);
        color: white;
        border-bottom: 3px solid rgba(255, 255, 255, 0.6);
      }
    }
    &.active {
      a {
        border-bottom: 3px solid rgba(255, 255, 255, 1);
      }
    }
  }
}
