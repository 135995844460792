.summer {

  // page layout

  &__main-content {
    width: 100%-($sidebar-width*2);
    float: left;
  	overflow-y: auto;
    padding: $space;
    @include ipad-p {
      float: right;
      padding: $space-tablet;
      width: 100%-($sidebar-width-tablet);
    }
    @include mobile { width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
  }

  &__calendar-content {
    width: 100%-($sidebar-width);
    float: left;
  	overflow-y: auto;
    padding: $space;
    @include ipad-p {
      float: right;
      padding: $space-tablet;
      width: 100%-($sidebar-width-tablet);
    }
    @include mobile { width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
  }

  &__sidebar-l {
    float: left;
  	width: $sidebar-width;
    padding: $space 0 $space $space;
    @include ipad-l { padding: $space-tablet 0 $space-tablet $space-tablet; }
    @include ipad-p {
      width: $sidebar-width-tablet;
      padding: $space-tablet 0 $space-tablet $space-tablet;
    }
    @include mobile { width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
  }
  &__sidebar-r {
    float: right;
  	width:  $sidebar-width;
    padding: $space $space $space 0;
    @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
    @include ipad-p {
      width: 100%-($sidebar-width-tablet);
      padding: 0 $space-tablet;
    }
    @include mobile { width: 100%; float: none; padding: $space-mobile; }
  }
  &__sidebar-section {
    margin-bottom: $space*(0.75);
    @include mobile { margin-bottom: 0; }
  }



  &__menu-button {
    display: block;
    color: white;
    background-color: $ui-primary;
    padding: 2rem;
    margin-bottom: .5rem;
    text-decoration: none;
    @include round-corners($corner-radius);
    @include fade(.4s);

    *{color: white; margin: 0;};

    &:hover,
    &.here {
      background-color: $ui-dark;
    }
    &.here {
      background-image: url('/assets/images-ui/ui-icon-nav-arrow.png');
      background-repeat: no-repeat;
      background-position: right;
      background-size: 20px;
      @include mobile {
        background-image: url('/assets/images-ui/ui-icon-here-tick.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: 30px;
      }
    }
  }


  &__boxout {
    margin: $space/2 0;
    padding: $space/2;
    @include round-corners($corner-radius);
  }



  // ENTRY PAGE ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

  &__intro {
    margin-bottom: $space;
  }

  &__overview {
    margin-top: $space;
    margin-bottom: 1rem;

    &-title {
      background-color: $ui-accent;
      padding: 1rem 2rem;
      @include round-corners( $corner-radius*2 $corner-radius*2 0 0 );
    }
    &-title-heading {
      color: white;
      margin: 0;
    }

    &-content {
      background-color: $ui-wash;
      padding: 2rem 3rem;
      @include round-corners( 0 0 $corner-radius*2 $corner-radius*2 );
      @include clearfix();
    }
    &-description {
      float: left;
      width: 70%;
      padding-right: 3rem;
    }
    &-details {
      float: right;
      width: 30%;
    }
  }

  // calendar section

  &__calendar-item {

    &-offers {
      @include mobile {
        margin-top: 1rem;
      }
    }
    &-icon {
      height: 2.5rem;
      margin: .25rem;
    }
  }

  &__popup-link {
    text-decoration: none;
  }
  &__popup-icon {
    width: 6rem;
  }


  // entry page sidebar-module

  &__sidebar-offer {
    display: flex;
    align-items: center;

    &-left {
      width: 5rem;
    }


    &-icon {
      width: 2.5rem;
      margin-right: 1.25rem;
    }
    &-title {
      font-size: 90%;
    }
  }



}
