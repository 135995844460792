.popup {
  position: relative;
  padding: $space*(0.7);
  width: auto;
  max-width: 60rem;
  margin: $space/2 auto;
  @include ipad-l { padding: $space-tablet*(0.85); }
  @include ipad-p { padding: $space-tablet*(0.85); }
  @include mobile { padding: $space-mobile*(0.85); }
}
.popup--white {
  @include round-corners($corner-radius*2);
  background: #FFF;
  overflow-y: auto;
}

// fade (referenced in js init)
.mfp-with-fade {
  // before-open state
  .mfp-content,
  .mfp-arrow,
  &.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }
  // open state
  &.mfp-ready {
    .mfp-content,
    .mfp-arrow {
      opacity: 1;
    }
    &.mfp-bg {
      opacity: 0.8; // background opacity
    }
  }
  // closed state
  &.mfp-removing {
    .mfp-content,
    .mfp-arrow,
    &.mfp-bg {
      opacity: 0;
    }
  }
}
