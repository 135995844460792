$card-grid-space: 2rem;

.update-card {

    background-color: white;
    padding: $space-sml;
    color: $ui-text;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-bottom: $card-grid-space;
  
    @include tablet-portrait-up {
        width: calc( (100% - #{$card-grid-space *2}) /3 );
        margin-right:  $card-grid-space;
        &:nth-child(3n+3) {margin-right: 0};
      }

    @include desktop-up{
        width: calc( (100% - #{$card-grid-space *3}) /4 );
        &:nth-child(3n+3) {margin-right: $card-grid-space};
        &:nth-child(4n+4) {margin-right: 0}
    }

    &__title {
        color: $brand-02 !important;
    }

    &__overview {
        margin-bottom: 1.5rem;
    }

}