.box-button {
  @include column(1/3, $gutter:3, $cycle:3);

  position: relative;
  height: 18rem;
  overflow: hidden;
  border: none;
  background-color: darken($brand-01, 10%);
  color: white;
  text-decoration: none;
  cursor: pointer;
  @include round-corners($corner-radius);
  @include fade(.4s);

  margin-bottom: 2rem;

  &:nth-child(-n+3) {margin-top: 0;}

  &:hover {
    background-color: darken($brand-01, 5%);
    @include fade(.4s);
  }

  @include ipad-p {
    @include column(1/2, $gutter:3, $cycle:2);
    // &:nth-child(-n+3) {margin-top: 0;}
    // &:nth-child(-n+2) {margin-top: 0;}
  }

  @include mobile {
    @include stack();
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 16rem;
    &:nth-child(-n+3) {margin-top: 0.5rem;}
  }
}

.box-button p {
  font-size: $base-font-size*(1.2);
  font-weight: 300;
  margin-bottom: 0;
  @include mobile { font-size: $base-font-size; }
}

.box-button--sml {
  height: 10rem;
  p {
    font-size: $base-font-size;
    font-weight: 300;
  }
}

.box-button__container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: $space*(0.15) $space*(0.4);
  text-align: center;
  * {line-height: 1.3;}
  @include mobile { padding: $space-mobile;}
}
