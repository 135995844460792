.box > * {
  margin: 0;
}

.box {
  @include round-corners($corner-radius*2);
  padding: $space/3;
  overflow: hidden;
  @include ipad-l { padding: $space-tablet/3; }
  @include ipad-p { padding: $space-tablet/3; }
}


.cta-box {
  display: table;
}
.cta-box__container {
  display: table-row;
}
.cta-box__container-l {
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  text-align: center;
  img {max-width: 80%}
}
.cta-box__container-m {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
  padding: 0 $space*(0.4);
}
.cta-box__container-r {
  display: table-cell;
  vertical-align: middle;
  width: 30%;
  text-align: right;
}
