.video {

}

.video__list-item {
  @include column(1/3, $gutter:4, $cycle:3);
  margin-top: $space;
  &:nth-child(-n+3) {margin-top: 0;}
  @include mobile { @include stack(); margin-bottom: $space-mobile; }
}

.video__wrapper {
	margin: 0 auto 1.5rem;
	overflow-y: auto;
	max-width: 100%;
	.video__container {
		position: relative;
		padding-bottom: 56.5%;
		padding-top: 0px;
		height: 0;
		overflow: hidden;
		iframe, object, embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
