$subscribe-space: 1.5rem;
$subscribe-font-size: 2rem;

.subscribe {

    clear: both;
    padding: $space-sml;
    text-align: center;

    @include tablet-portrait-up {
        padding: $space-lrg;
    }


    &__heading {
        margin-bottom: $space-sml;
    }


    input[type='text'],
    input[type='email'] {
        display: inline-block;
        font-family: 'Montserrat', Helvetica, sans-serif;
        font-weight: 400;
        font-size: $subscribe-font-size*(0.75);
        padding: $subscribe-space $subscribe-space*2;
        border-radius: $button-radius;
        border: none;
        background-color: white;
        width: auto;

        @include mobile {
            display: block;
            width: 100%;
            margin-bottom: 1rem;
        }
        @include tablet-portrait-up {
            margin-right: 0.75rem;
        }
        @include tablet-landscape-up {
            font-size: $subscribe-font-size;
        }
    }

    input[type='submit'] {
        display: inline-block;
        border: none;
        background-color: $brand-01;
        color: white;
        cursor: pointer;
        font-family: 'Montserrat', Helvetica, sans-serif;
        font-weight: 400;
        font-size: $subscribe-font-size*(0.75);
        padding: $subscribe-space $subscribe-space*2;
        border-radius: $button-radius;
        transition: all 300ms ease;

        &:hover {
            background-color: $ui-dark-grey;;
        }

        @include mobile {
            display: block;
            width: 100%;
        }
        @include tablet-landscape-up {
            font-size: $subscribe-font-size;
        }
    }

}