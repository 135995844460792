// side bar calendar (programe pages)

.calendar {

  &__class {
    background-color: $ui-wash;
    padding: 1rem;
    @include round-corners($corner-radius*2);

    margin-top: $space*(0.2);
    @include ipad-l {
      margin-top: $space-tablet*(0.2);
      p { line-height: $base-line-height-tablet; }
    }
  }

  &__class__title {
    margin-bottom: .2rem;
    a {font-weight: 400; color: $ui-text}

    &--full {
      color: $ui-light-grey;
    }
  }

  &__class__time {
    margin-bottom: 0;
  }

  &__class__ref {
    margin-bottom: 0;
  }

  &__class__frequency {
    color: $ui-mid-grey;
  }

  &__class__cta {
    margin-top: .5rem;
  }

  &__class__badge {
    @include round-corners($base-font-size);
    display: inline-block;
    min-width: $base-font-size *2.2;
    text-align: center;
    padding: .1rem 1.25rem;
    font-weight: 500;
    font-size: $base-font-size -(0.2rem);
    color: white;
    background-color: $brand-01;
    @include ipad-l {
      font-size: $base-font-size -(0.3rem);
      margin: 0.1rem 0;
    }
    @include ipad-p {
      font-size: $base-font-size -(0.3rem);
      margin: 0.1rem 0;
    }

    &--full {
      background-color: $ui-light-grey;
    }
  }

  &__class__enrol-btn {
    text-decoration: none;
    font-weight: 500;
    @include fade(.6s);
    @include round-corners($base-font-size);
    display: inline-block;
    text-align: center;
    color: white;
    text-transform: uppercase;
    padding: .1rem 1rem .1rem 1.2rem;
    font-weight: 500;
    font-size: $base-font-size -(0.2rem);
    background-color: $brand-01;
    &:hover{
      @include fade(.3s);
      background-color: $ui-dark-grey;
    }
    @include ipad-l {
      font-size: $base-font-size -(0.3rem);
      margin: 0.1rem 0;
    }
    @include ipad-p {
      font-size: $base-font-size -(0.3rem);
      margin: 0.1rem 0;
    }
  }

  &--sidebar {
    margin-bottom: $space *(0.5);
  }


}


// CLASS CALENDAR //////////////////////////////////////////////////////////////


// class calendar sidebar

.calendar__filter-toggle {
  display: none;
  @include mobile {
    display: block;
    margin-bottom: 2rem;
  }
}

.calendar__filter {
  @include mobile {
    overflow: hidden;
    height: 0;
    transition: all .5s ease;

    &.open {
      height: auto;
    }
  }
}


.calendar__sidebar-button {
  display: block;
  background-color: $brand-01;
  padding: 2rem;
  margin-bottom: .5rem;
  text-decoration: none;
  @include round-corners($corner-radius);
  @include fade(.4s);

  *{color: white; margin: 0;};

  &:hover,
  &.here {
    background-color: $ui-dark;
  }
  &.here {
    background-image: url('/assets/images-ui/ui-icon-nav-arrow.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px;
    @include mobile {
      background-image: url('/assets/images-ui/ui-icon-here-tick.png');
      background-repeat: no-repeat;
      background-position: right;
      background-size: 30px;
    }
  }
}


// class calendar list page

$list-item-padding: $space*(0.3);
$list-item-space-v: $space*(0.3);

.calendar__title {
  @include mobile { text-align: center; }
}
.calendar__section-divider {
  @include round-corners($corner-radius);
  padding: $space*(0.15) $space*(0.25);
  background-color: $brand-01;
  margin-bottom: $list-item-space-v;
  h1, h2, h3, h4, h5, h6 {color: white; margin: 0}
  @include mobile { text-align: center; }
}
.calendar__list-item {
  @include round-corners($corner-radius);
  border: 2px solid $ui-wash;
  margin-bottom: $list-item-space-v;
}

.calendar__list-item {
  display: table;
  width: 100%;
  @include mobile { display: block; }
}
.calendar__list-item__container {
  display: table-row;
  @include mobile { display: block; }
}
.calendar__list-item__date,
.calendar__list-item__class,
.calendar__list-item__seats,
.calendar__list-item__details {
  display: table-cell;
  vertical-align: middle;
  @include mobile { display: block; }
}

.calendar__list-item__container {
  display: table-row;
}
.calendar__list-item__date {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
  background-color: $ui-wash;
  padding: $list-item-padding;
  text-align: center;
  h1, h2, h3, h4, h5, h6 {margin: 0; line-height: 1}
  @include mobile {
    display: block;
    width: 100%;
    padding: $space-mobile/3;
    p { margin-bottom: 0; }
  }
}
.calendar__list-item__class {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
  padding: $list-item-padding;
  background-color: white;
  @include mobile { display: block; width: 100%; padding-bottom: 0; * {text-align: center; }}
}
.calendar__list-item__offer {
  //position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  padding: $space*(0.2);
  text-align: center;
  @include mobile {
    display: block;
    width: 100%;
    padding: 0;
    img.special-offer__flash {
      width: 3rem;
    }
  }
}
.calendar__list-item__details {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
  padding: $space*(0.2);
  text-align: right;
  @include mobile { display: block; width: 100%; padding-top: 0; }
}
