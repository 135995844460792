@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i');

// * { background-color: rgba(88, 238, 252, 0.19); }

.testing {
	margin: .5rem 0;
	background-color: orangered;
	padding: .5rem;
	color: white;
}

* {
	margin: 0;
	padding: 0;
	font-weight: 300;
}

*, *:before, *:after {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	font-family: 'Montserrat', Helvetica, sans-serif;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	color: $ui-text;
	background-color: $site-bk-color;
	@include ipad-l { font-size: $base-font-size - 0.2rem; }
	@include ipad-p { font-size: $base-font-size * 0.8; }
	@include mobile { font-size: $base-font-size * 0.9; }
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-01;
	line-height: 1.2;
	margin-bottom: 1rem;
	font-weight: 200;
}
p, ul, ol {
	margin-bottom: 1rem;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
	@include ipad-l { line-height: $base-line-height-tablet; }
	@include ipad-p { line-height: $base-line-height-tablet; }
}

p.standfirst {
	font-size: $base-font-size *1.2;
	margin-bottom: $base-font-size *2;
}
h1 {
	font-size: $base-font-size *3; margin-bottom: 1.75rem;
	@include ipad-l { font-size: $base-font-size *2.5; margin-bottom: 1.65rem; }
	@include ipad-p { font-size: $base-font-size *2.25; margin-bottom: 1.65rem; }
	@include mobile { font-size: $base-font-size *2; margin-bottom: 1rem; }
}
h2 {
	font-size: $base-font-size *2.25;
	@include ipad-l { font-size: $base-font-size *2; }
	@include ipad-p { font-size: $base-font-size *2; }
	@include mobile { font-size: $base-font-size *1.6 }
}
h3 {
	font-size: $base-font-size *2;
	@include ipad-l { font-size: $base-font-size *1.5; }
	@include ipad-p { font-size: $base-font-size *1.5; }
	@include mobile { font-size: $base-font-size *1.35; }
}
h4 {
	font-size: $base-font-size *1.3; font-weight: 400;
	@include ipad-l { font-size: $base-font-size *1.1; }
	@include ipad-p { font-size: $base-font-size *1.1; }
	@include mobile { font-size: $base-font-size *1.2; }
}
h5 {
	font-size: $base-font-size *1.2; font-weight: 400;
	@include ipad-l { font-size: $base-font-size *1; }
	@include ipad-p { font-size: $base-font-size *1; }
	@include mobile { font-size: $base-font-size *1.1; }
}
h6 {
	font-size: $base-font-size *1.1; font-weight: 500;
	@include ipad-l { font-size: $base-font-size *0.9; }
	@include ipad-p { font-size: $base-font-size *0.9; }
	@include mobile { font-size: $base-font-size *0.9; }
}

a {
	color: $brand-01;
}
ul, ol {
	margin-left: $base-font-size;
}
strong {
	font-weight: 500;
}

img {
	max-width: 100%;
}



.dark-bold {
	font-weight: 500;
	color: $ui-text;
}
.txt-primary {
	color: $ui-primary;
}


.wrapper {
	max-width: $site-max-width;
	margin: 0 auto;
}


.title-slug {
	padding: .75rem 1.2rem;
	background-color: $brand-01;
	color: white;
}


// space adjustments for buttons appearing after paragraphs

p a.button, p a.button-cta {
    margin-top: 2rem;
}


// redactor editable font colours

.color-brand-01 {
    color: $brand-01;
}
.color-brand-02 {
    color: $brand-02;
}
.color-brand-03 {
    color: $brand-03;
}
.color-brand-04 {
    color: $brand-04;
}


// backgrounds

.bg-brand-01 {
	background-color: $brand-01;
	color: white;
	* {color: white}
}
.bg-brand-02 {
	background-color: $brand-02;
	color: white;
	* {color: white}
}
.bg-brand-03 {
	background-color: $brand-03;
	color: white;
	* {color: white}
}
.bg-brand-04 {
	background-color: $brand-04;
}
.bg-brand-05 {
	background-color: $brand-05;
}
.bg-brand-06 {
	background-color: $brand-06;
}



.bg-white {
	background-color: white;
}
.bg-wash {
	background-color: $ui-wash;
}
.bg-warm {
	background-color: $ui-warm;
	color: white;
	h1, h2, h3, h4, h5, h6 {color: white}
	a {color: white}
}
.bg-bright {
	background-color: $ui-bright;
}
.bg-primary {
	background-color: $brand-01;
	color: white;
	h1, h2, h3, h4, h5, h6 {color: white}
	// .button {background-color: darken($brand-01, 5%);}
}
.bg-dark {
	background-color: $brand-01;
	color: white;
	h1, h2, h3, h4, h5, h6 {color: white}
	a {color: white}
}
.bg-black {
	background-color: $ui-dark-grey;
}


.circular {
	@include round-corners (50%);
	margin-bottom: $space*(0.25);
	width: 6rem;
	height: 6rem;
	background-color: darken($ui-wash, 10%);
	@include ipad-l { width: 5rem; height: 5rem; }
	@include ipad-p { width: 4.5rem; height: 7rem; }
	@include mobile { width: 4rem; height: 4rem; }
}


.icon {
	border: 0;
}
.icon--med {
	height: 5rem;
	margin-bottom: $space*(0.2);
	@include ipad-p { height: 4.25rem; }
}
.icon--top {
	height: 4.5rem;
	margin-bottom: $space*(0.3);
	@include ipad-p { height: 3.75rem; }
	@include mobile { height: 3.5rem; margin-top: 1rem; margin-bottom: 0.5rem; }
}
.icon--left {
	height: 5.5rem;
	float: left;
	margin-right: $space*(0.2);
	margin-left: ($side-icon-margin)*(-1);
	@include ipad-p { height: 4.5rem; }
	@include mobile { float: none; margin: .5rem 0; height: 4rem;}
}

.social {
	margin-top: $space*(0.4);
	a {
		margin-right: 0.5rem;
	}
}
.social__icon {
	height: 3.2rem;
}

.pagination {
	clear: both;
	margin-top: $space;
	list-style: none;
	margin-left: 0;
	li.prev {
		float: left;
	}
	li.next {
		float: right;
	}
}

.pagination-bar {
	clear: both;
	padding-top: $space;
	.prev {
		float: left;
	}
	.next {
		float: right;
	}
}


.breadcrumbs {
	padding: $space*(0.5) $space*(0.5) 0;
	font-size: $base-font-size*(0.9);
	font-weight: 300;
	@include ipad-l { font-size: $base-font-size - 0.2rem; }
	@include ipad-p { font-size: $base-font-size * 0.8; }

	@include tablet-portrait-up {
		padding: $space*(0.5) $space 0;
	}
}




ul.category-list {
	list-style: disc;
	margin-left: $base-font-size +(.2);
	li {
		margin-bottom: 0;
		font-weight: $base-font-weight;
		color: $brand-01;
		a {
			font-weight: $base-font-weight;
			color: $brand-01;
			text-decoration: none;
			&:hover {text-decoration: underline;}
		}
	}
}
