.header__brand-bar {
  max-width: $site-max-width;
  margin: 0 auto;
  
  padding: $space*(0.25) $space*(0.5);
  background-color: white;
  width: 100%;
  display: table;
  @include ipad-p { height: 8.5rem; }
  @include mobile { height: 7.5rem; };

  @include desktop-up {
    padding: $space*(0.25) $space;
  }
}

.header__brand-bar__container {
  display: table-row;
}
.header__brand-bar__container__l {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.header__brand-bar__container__r {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  // @include ipad-p { padding-right: 7rem; }
  // @include mobile { padding-right: 5.5rem; }
  @include mobile { display: none; }
  @media only screen and (max-width : 954px) { padding-right: 6rem; }
}

.header__brand-bar__logo {
  height: 5rem;
  width: auto;
 
  @include tablet-portrait-up {
    height: 6rem;
    width: auto;
  }
  @include tablet-landscape-up {
    height: 7.5rem;
    width: auto;
    margin-right: $space*(0.5);
  }
}
.header__brand-bar__endorsement {
  max-width: 30rem;
  display: inline-block;
  font-weight: 400;
  line-height: $base-line-height*(0.9);
  color: white;
}
.header__brand-bar__seal {
  width: 10rem;
  display: block;
  float: right;
  @include ipad-l { width: 8rem; height: auto; }
  @include ipad-p { width: 7rem; height: auto; }
  @include mobile { width: 6rem; height: auto; }
}
