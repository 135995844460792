.footer {
	clear: both;
	overflow-y: auto;
	padding: $space;

	color: $ui-text;

	a { 
		color: $ui-text;
		text-decoration: none;
		transition: color 300ms ease;

		&:hover {
			color: $ui-text;
		}
	}

	li { margin-bottom: .4rem; }

	@include ipad-l { padding: $space-tablet; }
	@include ipad-p { padding: $space-tablet; }
	@include mobile { padding: $space-mobile; }

	&__menu {
		float: left;
		width: 70%;
		@include mobile { float: none; width: 100%;}
	}

	&__menu-list {
		list-style: none;
		margin-left: 0;
	}

	&__menu-list--bold {
		a {font-weight: 500;}
	}

	&__contact {
		float: right;
		width: 30%;
		padding-left: $space;
		@include mobile { float: none; width: 100%; padding-left: 0;}
	}

	&__cervantes-seal {
		margin: 1rem 0;
		height: 12rem;
	}

}



.footer-bar {
	clear: both;
	overflow-y: auto;
	padding: $space;
	background-color: $ui-dark-grey;
	color: $ui-mid-grey;

	a { 
		color: $ui-mid-grey;
		text-decoration: none;

		&:hover {
			color: white;
		}
	}

	@include ipad-l { padding: $space-tablet; }
	@include ipad-p { padding: $space-tablet; }
	@include mobile { padding: $space-mobile; }

	&__icon {
		height: 3.2rem;
		width: auto;
		margin-left: 1.5rem;
	}

	&__legal {
		float: left;
		width: 70%;
		@include mobile { float: none; width: 100%;}
	}

	&__social {
		float: right;
		width: 30%;
		text-align: right;
		padding-left: $space-tablet;
		@include mobile { float: none; width: 100%; padding-left: 0; text-align: left;}
	}

}