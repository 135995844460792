.header {

    &__top-bar {

        width: 100%;
        padding: $space*(0.25) $space*(0.5);
        color: white;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop-up {
            padding: $space*(0.25) $space;
        }


        
        &__section {
            display: flex;
            align-items: center;
        }

        &__phone {
            font-weight: 300;
            margin-right: $space*(0.4);
        }
        &__icon {
            height: 2rem;
            width: auto;
            margin-bottom: -.6rem;
            margin-right: 1rem;
        }
    }



    &__search {

        display: flex;
        align-items: center;
        padding-right: 1rem;

        &-input {
            display: inline-block;
            margin: 0;
            padding: .5rem 1.4rem;
            width: 20rem;
            font-size: $base-font-size;
            line-height: $base-line-height;
            border: none;
            border-radius: ($button-radius);
            background-color: white;
            color: #999;
        }

        &-button {
            border: none;
            font-size: $base-font-size;
            text-decoration: none;
            cursor: pointer;
            padding: $space*(0.15) !important;
            background: transparent !important;

            &:hover { background-color: transparent !important; }
        }

        &-icon {
            display: block;
            height: 2.2rem;
            margin-bottom: -.7rem;
        }

    }

    &__search-classes-button {

        border: none;
        background-color: $brand-02;
        color: white;
        font-size: $base-font-size;
        text-decoration: none;
        cursor: pointer;
        padding: $space*(0.15) $space*(0.4);
        border-radius: ($button-radius);
        @include fade(.4s);
    
        &:hover {
        background-color: $ui-dark-grey;;
            @include fade(.4s);
        }

        @include tablet-landscape-up {
            margin-right: 1rem;
        }

    }

    &__lang-switch {

        display: inline-block;
        margin: 0 $space*(0.25);
        a {
            color: white;
            text-decoration: none;
        }

    }

}