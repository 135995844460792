.card {

    flex: 1;

    background-color: white;
    display: flex;
    flex-direction: column;

    @include mobile { margin-bottom: $space-sml; }

    &__hero {
        display: block;
    }

    &__content {
        padding: 2rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__footer {
        padding-top: 1rem;
    }

    &__title {
        color: $brand-01;
    }

    &__text {
        color: $ui-text;
    }

}