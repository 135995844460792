.banner-slider {
  position: relative;
  z-index: 1;

  &__slide {
    position: relative;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    p,h1,h2,h3,h4,h5,h6 { color: white; }

    padding: $space-sml;
    @include tablet-portrait-up {
      padding: $space-med;
    }
    @include tablet-landscape-up {
      padding: $space-lrg 40% $space-lrg $space-lrg;

      p { font-size: $base-font-size *1.5; margin-bottom: 3rem;}
      h1 { font-size: $base-font-size *4; }
      h2 { font-size: $base-font-size *3.5; }
      h3 { font-size: $base-font-size *2.75; }
      h4 { font-size: $base-font-size *2; font-weight: 400; }
      h5 { font-size: $base-font-size *1.75; font-weight: 400; }
      h6 { font-size: $base-font-size *1.35; font-weight: 500; }

    }

    &--tinted { background-color: rgba(0, 0, 0, 0.25); }

    &--centred { text-align: center; }

  }

  &__image{
    width: 100%;
  }



  &__button {
    @include fade(.4s);
    @include round-corners(50%);
    background-color: rgba(0,0,0, 0.1);
    width: 5rem;
    height: 5rem;
    border: 2px solid white;
    padding: 1rem;
    cursor: pointer;
    outline: none;
    color: white;
    font-size: 1.5rem;
    line-height: 1;
    &:hover {
      background-color: rgba(0,0,0, 0.4);
      @include fade(.4s);
    }

    &--prev {
      position: absolute;
      z-index: 5;
      bottom: $space-sml;
      left: $space-sml;
      @include tablet-portrait-up {
        bottom: $space-med;
        left: $space-med;
      }
      @include tablet-landscape-up {
        bottom: $space-lrg;
        left: $space-lrg;
      }
     }

    &--next {
      position: absolute;
      z-index: 5;
      bottom: $space-sml;
      left: calc(#{$space-sml} + 5rem + 1rem );
      @include tablet-portrait-up {
        bottom: $space-med;
        left: calc(#{$space-med} + 5rem + 1rem );
      }
      @include tablet-landscape-up {
        bottom: $space-lrg;
        left: calc(#{$space-lrg} + 5rem + 1rem );
      }
    }
    
  }

}
