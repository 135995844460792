.header__main-menu {
  
}


// (desktop)
@media only screen and (min-width : 955px) {

	.mob {display: none;}

	.overlay {
		z-index: 3;
		position: relative;
		max-width: $site-max-width;
		margin: 0 auto;
  }

	.overlay ul {
		clear: both;
		display: block;
		background-color: $dtop-menu-bar-col;
		list-style: none;
		padding: 0 $space*(0.5);
		margin: 0 0 0 0;

		@include desktop-up {
			padding: 0 $space;
		}
  }

  .overlay ul li {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    @include ipad-l {margin-bottom: 1.2rem;}
    margin-right: 16px;
    a {
      @include fade(.3s);
      color: $dtop-menu-text;
      text-decoration: none;
      font-weight: 200;
      padding-bottom: 10px;
    }
    &:hover {
      a {
        @include fade(.3s);
        color: $dtop-menu-text;
      }
      ul {
				display: block;
			}
    }
    &.active {
      a {
        // border-bottom: 3px solid red;
      }
    }

    &.dropdown {
      &::after {
        content: "\25BE";
				color: $dtop-menu-text;
				margin-left: .3rem;
      }
    }
  }

	.overlay ul li ul {
		display: none;
		position: absolute;
		min-width: 250px;
		padding: 0;
		background-color: rgba(255, 255, 255, 0.5);
		padding-top: 10px;
		&:hover {
			ul {
				display: block;
			}
		}
	}

  .overlay ul li ul li {
    display: block;
    float: none;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0;
    a {
      @include fade(.3s);
      display: block;
      padding: $dtop-menu-padding*0.8 $dtop-menu-padding;
      background-color: white;
      border-bottom: 1px solid $ui-wash;
    }
    &:hover {
      a {
        @include fade(.3s);
        background-color: $ui-wash;
        //border-bottom: 3px solid red;
      }
    }



	}

}

// (devices)

@media only screen and (max-width : 954px) {

	.dtop {display: none;}

	.button_container {
		position: fixed;
		top: 20px;
		right: 20px;
		cursor: pointer;
		z-index: 100;

		height: $mob-menu-button-height;
		width: $mob-menu-button-width;
		background-color: $mob-menu-button-bkgd;

		&.active {
			.top {
				transform: translateY(8px) translateX(0) rotate(45deg);
			}
			.middle {
				opacity: 0;
			}

			.bottom {
				transform: translateY(-8px) translateX(0) rotate(-45deg);
			}
		}

		span {
			background-color: $mob-menu-button-bars;
			border: none;
			height: 3px;
			width: 50%;
			position: absolute;
			top: 10px;
			left: 10px;
			transition:  all .35s ease;
			cursor: pointer;

			&:nth-of-type(2) {
				top: 18px;
			}

			&:nth-of-type(3) {
				top: 26px;
			}
		}
	}

	.overlay {
		z-index: 3;
		position: fixed;
		background: $mob-menu-overlay-bkgd;
		top: 0;
		left: 0;
		width: 100%;
		height: 0%;
		opacity: 0;
		visibility: hidden;
		transition: opacity .35s, visibility .35s, height .35s;
		overflow: hidden;
    overflow-y: scroll;

		&.open {
		opacity: 1;
		visibility: visible;
		height: 100%;

			li {
				animation: fadeInRight .5s ease forwards;
				animation-delay: .35s;

				&:nth-of-type(2) {
					animation-delay: .4s;
				}
				&:nth-of-type(3) {
					animation-delay: .45s;
				}
				&:nth-of-type(4) {
					animation-delay: .50s;
				}
				&:nth-of-type(5) {
					animation-delay: .55s;
				}
				&:nth-of-type(6) {
					animation-delay: .60s;
				}
				&:nth-of-type(7) {
					animation-delay: .65s;
				}
        &:nth-of-type(8) {
					animation-delay: .70s;
				}
        &:nth-of-type(9) {
					animation-delay: .75s;
				}
        &:nth-of-type(10) {
					animation-delay: .80s;
				}
			}
		}

		nav {
			position: relative;
			font-size: 18px;
			padding: 18px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin-left: 0;
			display: block;
			position: relative;
			height: 100%;

			li {
				display: block;
				position: relative;
				opacity: 0;

				a {
					display: block;
					position: relative;
					color: $mob-menu-link-col;
					text-decoration: none;
					overflow: hidden;
					padding: 0 0 5px;

					&:hover:after,
					&:focus:after,
					&:active:after {
						width: 100%;
					}
				}

				ul {
					max-height: 0;
					overflow: hidden;
					-webkit-transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					transition: all 0.5s ease;


					background: rgba(255, 255, 255, 0.5);
					margin: 0 -40px;
					padding: 0 40px;
          li {
            a {
              color: #999;
              padding-left: 1rem;
            }
          }
				}
				&:hover {
					ul {
						max-height: 300px;
						display: block;
						//margin: 15px -40px;
						padding: 5px 40px;
					}
				}
			}
		}

    .mobile-menu-extras {
      position: fixed;
      left: $space-mobile;
      right: 0;
      bottom: 0;
			padding-bottom:$header-padding;
			padding-right: $space-mobile;
      color: $ui-text;
      &__locale-switch {
        border-radius: $button-radius;
        background-color: $ui-wash;
        margin: 1rem 0;
        text-align: center;
        line-height: 1.2;
        padding: 1rem 2rem;
				color: lighten($ui-text, 40%);
				a { text-decoration: none; }
			}

			.mobile-menu-extras__social-mob {
				@include mobile {
					margin-top: 1rem;
					text-align: center;
				}
				@include tablet-portrait-up {
					display: inline-block;
					margin-left: 2rem;
				}
				a {
					display: inline-block;
					&:not(:last-child) {
						margin-right: 1rem;
					}
				}
				img {
					height: 2.75rem;
					@include tablet-portrait-up {
						margin-bottom: -.6rem;
					}
				}
			}
			
			.button--cta {
				@include mobile { width: 100%; text-align: center; }
			}
			
    }

	}
	@keyframes fadeIn {
		0% {opacity: 0;}
		100% {opacity: 1;}
	}
	@keyframes fadeInRight {
		0% {
			opacity: 0;
			left: 20px;
		}
		100% {
			opacity: 1;
			left: 0;
		}
	}


}
