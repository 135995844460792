.quotes {

    &__item {
        &:not(:last-child) {
            padding-bottom: $space-med;
            border-bottom: 1px solid $ui-wash;
            margin-bottom: $space-med;
        }
        @include tablet-portrait-up {
            display: flex;
        }
    }

    &__sidebar {
        min-width: 20rem;
    }
    
}