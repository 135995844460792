.blog {

}

// index page
.blog__card {
  @include column(1/3, $gutter:4, $cycle:3);
  margin-top: $space;
  &:nth-child(-n+3) {margin-top: 0;}
  @include ipad-p {
    @include column(1/3, $gutter:3, $cycle:3);
    margin-top: $space-tablet;
    &:nth-child(-n+3) {margin-top: 0;}
  }
  @include mobile {
    @include stack();
    margin-bottom: 2rem;
  }
}
.blog__img-sml {
  width: 100%;
  margin-bottom: 1rem;
  @include round-corners($corner-radius);
}


// entry page
.blog__main-content {
  float: left;
  width: 100%-($sidebar-width);
  padding: $space;
  @include ipad-l { padding: $space-tablet; }
  @include ipad-p { padding: $space-tablet; width: 100%-($sidebar-width-tablet); }
  @include mobile { float: none; width: 100%; padding: $space-mobile; }
}
.blog__sidebar {
  float: right;
	width:  $sidebar-width;
  padding: $space $space $space 0;
  @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet $space-tablet $space-tablet 0;
  }
  @include mobile {
    float: none;
    width: 100%;
    padding: $space-mobile;
    margin-top: $space-mobile;
    border-top: 1px solid #CFCFCF; }
}
.blog__img-lrg {
  max-width: 100%;
  margin: 1rem 0 2rem;
  @include round-corners($corner-radius);
}
