// category page

.exams__main-content {
  width: 100%-($sidebar-width*2);
  float: left;
	overflow-y: auto;
  padding: $space;
  @include ipad-p {
    float: right;
    padding: $space-tablet;
    width: 100%-($sidebar-width-tablet);
  }
  @include mobile { width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
}

.exams__sidebar-l {
  float: left;
	width: $sidebar-width;
  padding: $space 0 $space $space;
  @include ipad-l { padding: $space-tablet 0 $space-tablet $space-tablet; }
  @include ipad-p {
    width: $sidebar-width-tablet;
    padding: $space-tablet 0 $space-tablet $space-tablet;
  }
  @include mobile { display: none; width: 100%; float: none; padding: $space-mobile $space-mobile 0; }
}
.exams__sidebar-r {
  float: right;
	width:  $sidebar-width;
  padding: $space $space $space 0;
  @include ipad-l { padding: $space-tablet $space-tablet $space-tablet 0; }
  @include ipad-p {
    width: 100%-($sidebar-width-tablet);
    padding: 0 $space-tablet;
  }
  @include mobile { width: 100%; float: none; padding: $space-mobile; }
}
.exams__sidebar-section {
  margin-bottom: $space*(0.75);
  @include mobile { margin-bottom: 0; }
}
