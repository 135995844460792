.special-offer {
  @include round-corners($corner-radius*2);
  width: 100%;
  display: table;
  // background-color: $ui-wash;
  margin-bottom: $space*(0.5);

  &__title-bar {
    @include round-corners($corner-radius*2);
    background-color: $ui-accent;
    text-align: center;
    padding: 1rem;

    &-heading {
      color: white;
      margin: 0;
    }
  }
  &__offer-popups {
    // padding: 1rem;
  }
  &__popup {
    @include round-corners($corner-radius*2);
    background-color: $brand-01;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    @include column(1/2, $gutter:2, $cycle:3);
    @include mobile {
      @include stack();
      margin: 1rem 0 0 0;
    }

    &-title {
      color: white;
      font-size: 2rem;
      margin-bottom: .4rem;
    }
  }
  &__button {
    float: right;
    display: inline-block;
    border-radius: ($button-radius);
    background-color: $ui-accent;
    padding: .3rem 1.2rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
    &:hover {
      background-color: $ui-dark-grey;;
      @include fade(.4s);
    }
  }



  &__container {
    display: table-row;

    &-l {
      display: table-cell;
      vertical-align: middle;
      padding: $space*(0.25);
      width: 13rem;
      text-align: center;
      @include mobile { width: 8rem; vertical-align: top; }
    }
    &-r {
      display: table-cell;
      vertical-align: middle;
      padding: $space*(0.25) $space*(0.25) $space*(0.25) 0;
    }

  }

  &__flash {
    min-width: 7rem;
  }

  &__tooltip {
    @include round-corners($corner-radius*2);
    padding: $space*(0.25) $space*(0.35);
    background-color: $ui-dark;
    color: white;
    text-align: left;

  }

  &__inline {
    @include round-corners($corner-radius);
    background-color: $ui-accent;
    padding: .4rem 1rem;
    color: white;
  }

  &__text {
    color: $ui-accent;
    font-weight: 400;
    margin-right: .5rem;
  }


}
