.event-slider {
  padding: 0 $space*(1.8);
  position: relative;
  margin-bottom: $space/2;
  margin-left: $space;
  margin-right: $space;
  @include ipad-l { margin-bottom: $space-tablet/3; }
  @include ipad-p { margin-bottom: $space-tablet/3; }
  @include mobile {
    padding: 1rem $space-mobile/2;
    margin: 0 $space-mobile;
  }
}
.event-slider__slide {
  @include round-corners($corner-radius);
  margin: 0 $space/3;
  background-color: $ui-dark;
}
.event-slider__slide__title-bar {
  @include round-corners( $corner-radius $corner-radius 0 0 )
  background-color: $brand-02;
  padding: $space/2;
  text-align: center;
  @include ipad-l { padding: $space-tablet/2; }
  @include ipad-p { padding: $space-tablet/2; }
  @include mobile { padding: $space-mobile/2; }
}
.event-slider__slide__title-bar > * {
  margin: 0;
}
.event-slider__slide__content {
  padding: $space/2;
  color: white;
  text-align: center;
  @include ipad-l { padding: $space-tablet/3; }
  @include ipad-p { padding: $space-tablet/3; }
  @include mobile { padding: $space-mobile/2; }
}
